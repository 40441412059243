import React, { Component } from "react";
import moment from "moment";
import Month from '../components/chart';
import Week from '../components/week';
import Hour from '../components/hour';
import Day from '../components/day';
import { Link } from 'react-router-dom';
import start from '../img/work.svg';
import {DollarSign, Users, Calendar, MessageCircle, User, PhoneMissed, ZapOff} from 'react-feather';
import { CardDashboard, Card } from '../components/Card';
import { Wrap, Main, NavBar } from '../components/Card/styles';
import Access from "../components/accessManager";

class Home extends Component {
  state = {
    chartDefaults: {
      label: 'Total users',
      fill: true,
      borderColor: '#4e73df',
      borderCapStyle: 'square'
    },

    totalclients: 0,
    totaldealers: 0,
    totalagents: 0,
    totalsales: 0,
    failedRequestsCount: 0,
    records: [],
    loanBalance: 0,
    performanceFilter: {},
    SalesDataset: [],
    LoansDataset: [],
    AgentsDataset: [],
    DailyDataset:[],
    WeekDataset:[],
    smallWindow: window.innerWidth,

  };

  render() {    
    if (this.state.smallWindow < 750) {
      var card = '100%';
      var graph = '200%';
      var cardAlert="100%"
    } else {
      var graph = '48%';
      var card = "20%";
      var cardAlert="50%"
    }


   
    if (window.user.admin['group']['name'] === 'CCR' || window.user.admin['group']['name'] === 'Telkom  Support'){
      return (
        <div>
          <div className='mx-auto'>
            <div className='px-3 my-5'>
              <div className='d-flex flex-row align-items-center'>
                {/* <img src={logo} className='dasboard-logo mr-3' alt='' /> */}
                <div>
                  {/* <h1 className='mb-0 font-weight-bold text-primary'>
                    CredoFaster Portal
                  </h1> */}
                  <h1 className='mb-0 font-weight-bold text-primary'>
                    {window.hostname.charAt(0).toUpperCase() + window.hostname.slice(1)} Portal
                 </h1>
                </div>
              </div>
            </div>

            <div className='page-content px-3 position-relative'>
              <div className='card py-4 px-5 mt-2 intro-card text-white my-5'>
                <div className='row'>
                  <div className='col-md-8'>
                    <h4 className='font-weight-bold intro-title'>
                      Welcome Back !
                    </h4>
                    <div className='mt-3'>
                      To get started, use the menu on your left. All the general
                      summaries will be displayed on this page. Any new system
                      updates will be displayed here.
                    </div>
                  </div>
                  {/* <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
                    <img src={start} alt='' className='intro-img' />
                  </div> */}
                </div>
              </div>
            </div>
          </div>




          <div className='p-4'></div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='mx-auto'>
            <div className='px-4 my-2'>

              <div className='d-flex flex-row align-items-center' style={{ marginTop: '70px' }}>
                <div>
                  {/* <h1 className='mb-0 font-weight-bold text-primary'>
                    CredoFaster
                </h1> */}
                <h1 className='mb-0 font-weight-bold text-primary'>
                    {window.hostname.charAt(0).toUpperCase() + window.hostname.slice(1)}
                 </h1>
                </div>
              </div>
            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>

              <CardDashboard className="green" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Dealers</div>
                      <div className="number pulsate">{this.state.totaldealers.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Calendar size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>

              <CardDashboard className="blue" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Agents</div>
                      <div className="number pulsate">{this.state.totalagents.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <User size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="green" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Clients</div>
                      <div className="number pulsate"> {this.state.totalclients.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Users size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="orange" style={{ width: card }} >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Sales, Ksh</div>
                      <div className="number pulsate">{parseFloat(this.state.totalsales).toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <DollarSign size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>

            </div>


            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##fff' }}>

              <CardDashboard className="red" style={{ width: cardAlert }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto">
                      <ZapOff size="3em" />
                    </div>

                    <div className="col">
                      <div className="title">Failed Requests<i>Critical. Please Action on this</i></div>
                      <div className="number pulsate">Pending Requests awaiting Reconciliation: <b>{this.state.failedRequestsCount.toLocaleString()}</b><br/>

                      </div>
                    </div>
                    <br/>
                    <Link
                        to={"/request-log-failed"}
                        className={'btn nav-link text-dark'}>
                      Open View
                    </Link>

                  </div>
                </div>
              </CardDashboard>

             

            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Month
                  title='Sales Per Month'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    
                  }}
                  datasets={this.state.SalesDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Hour
                  title='Sales Per Hour'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.LoansDataset}
                />
              </div>


            </div>
            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Day
                  title='Sales Per Day'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    //console.log(filter);
                  }}
                  datasets={this.state.DailyDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Week
                  title='Sales Per Week'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.WeekDataset}
                />
              </div>


            </div>


          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.fetchTotals();
    this.fetchTotalSales();
    this.fetchMonth();
    this.fetchHour();
    this.fetchDay();
    this.fetchWeek();
    this.fetchFailedRequestsCount()
  }

  fetchTotals = () => {
    window.app
      .service('stats?type=total_user_types')
      .find({

      })
      .then((response) => {
       
        // response.data = response.data.map((d, i) => {

        // });

        this.setState({ totaldealers: response[0]['b'], totalclients: response[1]['b'], totalagents: response[2]['b'] });
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });
  };
  fetchTotalSales = () => {
    window.app
      .service('stats?type=total_sales')
      .find({

      })
      .then((response) => {
        


        this.setState({ totalsales: response[0]['total_sales'] });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  fetchFailedRequestsCount = () => {
    window.app
      .service('stats?type=failed_requests_count')
      .find({

      })
      .then((response) => {



        this.setState({ failedRequestsCount: response[0]['failed_requests_count'] });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  fetchMonth = () => {

    window.app
      .service('stats?type=total_month_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {         
          interest_gained.push({ t: d.a, y: d.b });
        });
        let datasets = [
          {
            label: 'Montly Sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ SalesDataset: datasets });
        console.log('month sales are',this.state.SalesDataset);

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchHour = () => {

    window.app
      .service('stats?type=total_hour_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });       
        let datasets = [
          {
            label: 'Hourly sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ LoansDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchDay = () => {

    window.app
      .service('stats?type=total_day_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });
       
        let datasets = [
          {
            label: 'Daily sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ DailyDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchWeek = () => {

    window.app
      .service('stats?type=total_week_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });
       
        let datasets = [
          {
            label: 'Weekly sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ WeekDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.performanceFilter }) !==
      JSON.stringify({ ...prevState.performanceFilter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchChart();
      }, 100);
    }
  }
}

export default Home;