import React, { Component } from 'react';
import moment from 'moment';
import { DollarSign, User, LogOut,Users } from "react-feather";

class UserSummarry extends Component {
  state = { data: {},account:{},dealer:{} };
  render() {
    return (
      <div>
        <div className='row p-3'>
        <div className="col-md-6">
        <div>
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <User size={18}></User> Customer Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
            </div>
              {Object.keys(this.state.dealer).length != 0?
            (<div style={{marginTop:"30px"}}>
             <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <Users size={18}></Users> Dealer Information
              </div>            
              {Object.keys(this.state.dealer).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.dealer[d]}</div>
                </li>
              ))}
            </ul>
            </div>):
            <div style={{marginTop:"30px"}}>
             <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                No Dealer Information
              </div> 
              </ul>
            </div>
          }


          </div>
          <div className="col-md-6">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <DollarSign size={18}></DollarSign> Account Details
              </div>
              {Object.keys(this.state.account).map(d => {
                if (d === "break" || d === "brea")
                  return <div className="p-1 bg-light"></div>;

                return (
                  <li className="list-group-item d-flex flex-row text-dark">
                    <div className="user-detail-title font-weight-bold text-capitalize">
                      {d.replace(/_/g, " ")}
                    </div>
                    <div>{this.state.account[d]}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    console.log('props and data is',this.props.data);
    let d = this.props.data;
    let sum = [];

    
    let data = {    
      cust_name: d.cust_name,     
      phone_number: d.msisdn,     
      date_created: moment(d.date_created).format('Do MMMM YYYY hh:mm'),     
      last_activity_date: d.last_activity_date,     
      status: (
        <span
          className={`badge badge-${
            window.global_status[d.status].theme
          }`}>
          {window.global_status[d.status]?.name}{' '}
        </span>
      ),
      user_type: (
        <span
          className={`badge badge-${
            window.userTypes[d.user_type]
          }`}>
          {window.userTypes[d.user_type]?.user_type}{' '}
        </span>
      ),
    };
    let account = {
      commission: "KES " +
      parseFloat(
        parseFloat(d.commission)
      ).toLocaleString(),
      net_value: "KES " +
      parseFloat(
        parseFloat(d.net_value)
      ).toLocaleString(),
      unit_consumed: "KES " +
      parseFloat(
        parseFloat(d.unit_consumed)
      ).toLocaleString(),
      unit_consumed: "KES " +
      parseFloat(
        parseFloat(d.unit_consumed)
      ).toLocaleString(), 
      points: d.points,          
     
    };
     window.app
      .service('customer')
      .get(d.agent_id)
      .then((response) => {
       this.setState(response);
      let dealer = {    
      cust_name: response.cust_name,     
      phone_number: response.msisdn,  
      date_created: moment(response.date_created).format('Do MMMM YYYY hh:mm'),     
      last_activity_date: response.last_activity_date,     
     
      user_type: (
        <span
          className={`badge badge-${
            window.userTypes[response.user_type]
          }`}>
          {window.userTypes[response.user_type]?.user_type}{' '}
        </span>
      ),   
   
    };
    this.setState({ dealer});
        
      })
      .catch((err) => {
        console.log('error is',err);     
      });






    this.setState({ data ,account});
  }
}

export default UserSummarry;
