import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from '../../components/modal';
import Form from '../../components/form';
import ReactJson from 'react-json-view';
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw,
  Users
} from "react-feather";

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    updateModal:false,
    addModal:false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Pull Results'
         
          buttons={[
            {
              text: 'Add Request',
              onClick: () => {
                this.setState({ addModal: true });
              },
              access: 'TRANSACTION_READ',
            }]}
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            

            <Table
              search={['transactionId','msisdn']}
               sort="transactionId"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchRequests();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'pullrequests',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
            <Modal
              visible={this.state.updateModal}
              close={() => this.setState({ updateModal: false})}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.updateRequest}>              
                {/* <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Initiator</span>
                  <input name="initiator_id" id="initiator_id" type="text" value={this.state.initiator_id} readOnly="readOnly" style={{ width: '400px' }} />

                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">reference_id</span>
                  <input type="text" name="reference_id" id="reference_id" value={this.state.reference_id} readOnly="readOnly" style={{ width: '400px' }} />
                </div> */}
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">transactionId:</span>
                  <input className="form-control bg-light text-input" name="transactionId" id="transactionId" type="text" value={this.state.transactionId} onChange={this.handleChange} style={{ width: '400px' }} />
               
                </div>
          
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
                </div>
              </form>
            </Modal>
             
            <Modal
          visible={this.state.addModal}
          close={() => this.setState({ addModal: false })}>
          <div className='d-flex flex-row align-items-center'>
            <Users className='mr-3'></Users>
            <h5 className='m-0 mt-1'>Create Request</h5>
          </div>
          <div className='mt-3'>
            <Form
              inputs={[
                {
                  label: 'transactionId',
                  name: 'transactionId',
                  type: 'text',
                  value: '',
                },
                {
                    // label: 'trxDate',
                    name: 'trxDate',
                    type: 'hidden',
                    value: Date.now(),                   
               
                  },
                  {
                    // label: 'request_id',
                    name: 'request_id',
                    type: 'hidden',
                    value: '0',                   
               
                  },
                {
                    label: 'msisdn',
                    name: 'msisdn',
                    type: 'text',
                    value: '',
                  },
                  {
                    label: 'amount',
                    name: 'amount',
                    type: 'text',
                    value: '',
                  },
                  {
                    // label: 'sender',
                    name: 'sender',
                    type: 'hidden',
                    value: 'MANUAL',
                    
               
                  },
                  {
                    // label: 'transactiontype',
                    name: 'transactiontype',
                    type: 'hidden',
                    value: 'MANUAL',
                    
               
                  },
                  {
                    // label: 'organizationname',
                    name: 'organizationname',
                    type: 'hidden',
                    value: 'MAWINGU AIRTIME LIMITED',
                    
               
                  },
                  {
                    // label: 'status',
                    name: 'status',
                    type: 'hidden',
                    value: 0,
                    
               
                  },
                  {
                    // label: 'billreference',
                    name: 'billreference',
                    type: 'hidden',
                    value: 'TILL_69902',
                    
               
                  },
                  {
                    // label: 'date_created',
                    name: 'date_created',
                    type: 'hidden',
                    value: Date.now(),                   
               
                  },
                  {
                    //  label: 'trxDateStr',
                    name: 'trxDateStr',
                    type: 'hidden',
                    value: "",                   
               
                  },
                  
                  
                  
              ]}
              submit={(data) => {
                this.createRequest(data);
              }}
            />
          </div>
        </Modal>
          </div>
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }

  fetchRequests = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

 

    window.app
      .service('mpesa-pull-results')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {            
            "transactionId": d.transactionId,
            // "trxDate": "2021-02-11T18:30:00.000Z",
            // "request_id": "67",
            "msisdn": d.msisdn,
            // "sender": "MPESA",
            "transactiontype": d.transactiontype,
            "billreference": d.billreference,
            "amount": d.amount,
            // "organizationname": "MAWINGU AIRTIME LIMITED",
            status: (
              <span
                className={`badge badge-${window.global_status[d.status]?.theme
                  }`}>
                {window.global_status[d.status]?.name}{' '}
              </span>
            ),
                            
            "date_created":moment.utc(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  updateRequest = (event) => {
    event.preventDefault();
      let id = this.state.id;
      let data = {
        transactionId: this.transactionId,
        // account_no: this.state.account_no,
        // reference_id: this.state.reference_id
        
      };
      if (
        !window.confirm("Are you sure that you want to reconcile this transaction?")
      )
        return false;

      // console.log(data)

      window.app
        .service('mpesa-pull-results')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Transaction updated Successfully',
            buttons: true,
          });
         this.refreshTable('default');
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
    
  };

  createRequest = (data) => {    
   
    console.log(data);

    // window.app
    //   .service('mpesa-pull-results')
    //   .create(data)
    //   .then(() => {
    //     // alert('added');
    //     this.setState({addModal: false });
    //     this.fetchRequests();
    //     // window.location.reload();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     this.setState({ modalVisible: false });
    //     // window.location.reload();
    //   });
  };
  handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }
}

export default Customers;
