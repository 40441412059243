import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filteralldatepicker';
import Access from '../../components/accessManager';

class Topups extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Customers'
          buttons={[
            {
              text: 'Add a Customer',
              link: '/customer/create',
              permission: 'CUSTOMER_CREATE',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>

            <Filter
                getFilter={filter => {
                   this.setState({
                   filter
                 }); 
                  setTimeout(() => {
                    this.fetchCustomers();
                  }, 0);
                }}
             
             dateRange={["startdate", "enddate"]}
            
           />
            <Table
              search={['narration','trx_id']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    let { query, filter } = this.state;
    let q = { ...query, ...filter }; 
    
   

    if (q.startdate) {
      q.date_created = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    window.app
      .service('service-client-trx-log')
      .find({
        query: {
          ...q,
          service_client_id:this.props.user
        
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            Record_no:d.id,            
            Narration: d.narration,           
            Transaction_Id: d.trx_id,             
            Debit: "KES "+parseFloat(d.dr).toLocaleString(), 
            Credit: "KES "+parseFloat(d.cr).toLocaleString(),
            Prev_Balance: "KES "+parseFloat(d.prev_bal).toLocaleString(),
            New_Balance: "KES "+parseFloat(d.new_bal).toLocaleString(),          
            date_created: moment(d.date_created).utcOffset('GMT+3').format('YYYY-MM-DD hh:mm:ss'),
            
         
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Topups;
