import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Topups extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Customers'
          buttons={[
            {
              text: 'Add a Customer',
              link: '/customer/create',
              permission: 'CUSTOMER_CREATE',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
         

            <Table
              //search={['narration', 'trx_id']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    let { query, filter } = this.state;
    let q = { ...query, ...filter };

    if (q.startdate) {
      q = {
        from_date: q.startdate,
        to_date: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    window.app
      .service('stats')
      .find({
        query: {
          ...q,
          client_id:this.props.user,
          type:'total_day_sales'
        
        },
      })
      .then((response) => {

        response.data= response.map((d) => {
          console.log('sales response',response)
          return {
            Sales:d.a,            
            Date: d.b,        
            
         
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Topups;
