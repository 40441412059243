import React, { Component } from 'react';
import Table from '../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import Modal from '../components/modal';
import Form from '../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import moment from 'moment';

class IPAdress extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='IP Adresses'
          buttons={[
            {
              text: 'Create IP Address',
              onClick: () => {
                this.setState({ modalVisible: true });
              },
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              // search={['firstname', 'lastname']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchIPAdress();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal visible={this.state.modalVisible}>
          <h5 className='font-weight-bold'>Create IP Address</h5>

          <hr />

          <div className=''>
            <Form
              inputs={[
                {
                  label: 'IP Address',
                  name: 'ip_address',
                  type: 'text',
                },
                {
                  label: 'Country',
                  name: 'country',
                  type: 'text',
                },
                {
                  label: 'state',
                  name: 'state',
                  type: 'select',
                  options: [
                    {
                      name: 'Whitelisted',
                      value: 'WHITELISTED',
                    },
                    {
                      name: 'Blacklisted',
                      value: 'BLACKLISTED',
                    },
                  ],
                },
                {
                  label: 'Description',
                  name: 'description',
                  type: 'textarea',
                },
              ]}
              submit={(data) => {
                this.setState({ modalVisible: false });
                this.createIP(data);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  fetchIPAdress = () => {
    this.setState({ table_loading: true });

    window.app
      .service('ip-address')
      .find({
        query: {
          ...this.state.query,
          cust_no: this.props.user,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            id: d.id,
            ip_address: d.ip_address,
            country: d.country,
            state: d.state,
            description: d.description,
            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
            action: (
              <button
                className='btn btn-sm btn-round btn-outline-danger px-3 mr-3'
                onClick={() => {
                  if (
                    !window.confirm(
                      'Are you sure that you want to delete this item?'
                    )
                  )
                    return false;
                  this.removeItem(d.id);
                }}>
                Delete
              </button>
            ),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  createIP = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Updating IP ...',
      buttons: false,
    });

    window.app
      .service('ip-address')
      .create(data)
      .then((response) => {
        this.fetchIPAdress();

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Updated Successfully',
          buttons: true,
          onSubmit: () => {},
        });
        this.setState(response);
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };

  removeItem = (id) => {
    window.alert2.show({
      loader: true,
      title: 'Removing Item ...',
      buttons: false,
    });

    window.app
      .service('ip-address')
      .remove(id)
      .then((response) => {
        this.fetchIPAdress();
        window.alert2.hide();
        window.alert2.notify({
          title: 'Removed Successfully',
        });
        this.setState(response);
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };
}

export default IPAdress;
