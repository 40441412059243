import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Dbqueue extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Messages'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
       

            <Table
              search={['Originator', 'Destination']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchMessages();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'messages',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMessages= () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

   

    window.app
      .service('dbcdr')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
           
            "Originator": d.Originator,
            "Destination": d.Destination,          
            "Message": d.Message, 
            "date_created": moment(d.MessageTimeStamp).format('YYYY-MM-DD hh:mm:ss'),
            
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Dbqueue;
