import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from "../../components/modal";
import Form from "../../components/form"
import { getTelco } from "../../components/phonevalidate"
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw
} from "react-feather";

class RequestsLogsFailed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: { data: [] },
      response: { data: [] },
      tableError: false,
      query: {},
      filter: {},
      table_loading: false,
      resendModal: false,
      reverseModal: false,
      errors: {},
      // id: "",
      // msisdn: " "
    };
  }


  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Reversed Requests'
        ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>


            <Table
              // table_id={"default"}
              search={['msisdn', 'payment_ref', 'provider','account_no']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchRequestFailed();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              csv={() => ({
                name: 'Requests',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />

            <Modal
              visible={this.state.reverseModal}
              close={() => this.setState({ reverseModal: false, errors: {} })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.resendRequest}>
                {/* <div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Id</span>
                            <input className="form-control bg-light text-input" name="id" id="id" type="text" value={this.state.id} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                        </div> */}
                {/* <div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Name</span>
                            <input className="form-control bg-light text-input" name="name" id="name" type="text" value={this.state.name} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                        </div> */}
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Amount</span>
                  <input name="amount" id="amount" type="text" value={this.state.amount} readOnly="readOnly" style={{ width: '400px' }} />

                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Msisdn</span>
                  <input type="text" name="msisdn" id="msisdn" value={this.state.msisdn} readOnly="readOnly" style={{ width: '400px' }} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                  <input className="form-control bg-light text-input" name="account_no" id="account_no" type="text" value={this.state.account_no} onChange={this.handleChange} style={{ width: '400px' }} />
                  <div className="text-danger">{this.state.errors.account_no}</div>
                </div>
                {window.user.admin['group']['name'] === 'System Admins' &&
                  <div className="mx-3 d-inline-block mb-3">
                    <span className="ml-1 mb-2 d-block text-capitalize">Provider:</span>
                    <select className="form-control bg-light text-input" name="provider" id="provider" type="text" value={this.state.provider} onChange={this.handleChange} style={{ width: '400px' }}>
                      <option value="SAFARICOMKE">SAFARICOMKE</option>
                      <option value="AIRTELKE">AIRTELKE</option>
                      <option value="TELKOMKE">TELKOMKE</option>
                      <option value="FAIBAKE">FAIBAKE</option>
                    </select>
                  </div>
                }
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Resend</button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  refreshTable(table_id) {
    document.getElementById(`${table_id}_refresh_table`).click()
  }

  fetchRequestFailed = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });


    window.app
      .service('request-log-failed')
      .find({
        query: {
          ...this.state.query,
          status_id:5,
        },
      })
      .then((response) => {
        console.log(response);
        response.data = response.data.map((d, i) => {
          return {

            "Mpesa Number": d.msisdn,
            "name": d.name,
            "Airtime Number": d.account_no,
            "payment_ref": d.payment_ref,
            "response": d.response,
            "amount": "KES " + parseFloat(d.amount).toLocaleString(),
            "provider": d.provider,

            status: (
              <span
                className={`badge badge-${window.global_status[d.status_id]?.theme
                  }`}>
                {window.global_status[d.status_id]?.name}{' '}
              </span>
            ),

            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
       



          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  resendRequest = (event) => {
    event.preventDefault();
    if (this.validate(this.state.account_no)) {
      let network = "";
      if (this.state.provider != undefined) {
        network = this.state.provider;
        console.log(network)
      } else {
        network = getTelco(this.state.account_no);
        console.log('network')

      }
      console.log('this is the ', network);

      let id = this.state.id;
      let data = {
        id: this.state.id,
        account_no: this.state.account_no,
        retry: 5,
        provider: network
      };
      if (
        !window.confirm("Are you sure that you want to Resend this transaction?")
      )
        return false;

      // console.log(data)

      window.app
        .service('request-log-failed')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Transaction resent Successfully',
            buttons: true,
          });
          this.refreshTable('default');
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
    }
  };
  validate(account_no) {
    let errors = {};
    let isValid = true;
    if (!account_no) {

      isValid = false;

      errors["account_no"] = "Please enter your phone number.";

    }
    if (typeof account_no !== "undefined") {

      var pattern = new RegExp(/^(\+){0,1}(254|0){0,1}(\d{9})$/);

      if (!pattern.test(account_no)) {

        isValid = false;

        errors["account_no"] = "Please enter valid phone number.";

      }

    }
    this.setState({

      errors: errors

    });



    return isValid;

  }
  reverse = (id) => {
    console.log(id);;
    let data = { status_id: 5 }
    if (
      !window.confirm("Are you sure that you want to Reverse this transaction?")
    )
      return false;

    window.app
      .service('request-log-failed')
      .patch(id, data)
      .then((response) => {
        this.setState(response);

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Transaction reversed Successfully',
          buttons: true,
        });
        this.refreshTable('default');

      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
        console.error(err);
      });
  };

  handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }


}

export default RequestsLogsFailed;
