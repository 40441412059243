import React, { Component } from "react";
import moment from "moment";
import Month from '../components/chart';
import Week from '../components/week';
import Hour from '../components/hour';
import Day from '../components/day';
import { Link } from 'react-router-dom';
import start from '../img/work.svg';
import {DollarSign, Users, Calendar, MessageCircle, User, PhoneMissed, ZapOff} from 'react-feather';
import { CardDashboard, Card } from '../components/Card';
import { Wrap, Main, NavBar } from '../components/Card/styles';
import Access from "../components/accessManager";


class Welcome extends Component {
  state = {
    chartDefaults: {
      label: 'Total users',
      fill: true,
      borderColor: '#4e73df',
      borderCapStyle: 'square'
    },


    smallWindow: window.innerWidth,

  };

  render() {    
    if (this.state.smallWindow < 750) {
      var card = '100%';
      var graph = '200%';
      var cardAlert="100%"
    } else {
      var graph = '48%';
      var card = "20%";
      var cardAlert="50%"
    }


   
    if (window.user.admin['group']['name'] === 'CCR' || window.user.admin['group']['name'] === 'Telkom  Support'){
      return (
        <div>
          <div className='mx-auto'>
            <div className='px-3 my-5'>
              <div className='d-flex flex-row align-items-center'>
                {/* <img src={logo} className='dasboard-logo mr-3' alt='' /> */}
                <div>
                  {/* <h1 className='mb-0 font-weight-bold text-primary'>
                    CredoFaster Portal
                  </h1> */}
                   <h1 className='mb-0 font-weight-bold text-primary'>
                    {window.hostname.charAt(0).toUpperCase() + window.hostname.slice(1)} Portal
                    </h1>
                </div>
              </div>
            </div>

            <div className='page-content px-3 position-relative'>
              <div className='card py-4 px-5 mt-2 intro-card text-white my-5'>
                <div className='row'>
                  <div className='col-md-8'>
                    <h4 className='font-weight-bold intro-title'>
                      Welcome Back !
                    </h4>
                    <div className='mt-3'>
                      To get started, use the menu on your left. All the general
                      summaries will be displayed on this page. Any new system
                      updates will be displayed here.
                    </div>
                  </div>
                  {/* <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
                    <img src={start} alt='' className='intro-img' />
                  </div> */}
                </div>
              </div>
            </div>
          </div>




          <div className='p-4'></div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='mx-auto'>
            <div className='px-4 my-2'>

              <div className='d-flex flex-row align-items-center' style={{ marginTop: '70px' }}>
                <div>
                  {/* <h1 className='mb-0 font-weight-bold text-primary'>
                    CredoFaster
                </h1> */}
                 <h1 className='mb-0 font-weight-bold text-primary'>
                    {window.hostname.charAt(0).toUpperCase() + window.hostname.slice(1)}
                 </h1>
                </div>
              </div>
            </div>


          </div>
        </div>
      );
    }
  }

  componentDidMount() {
  }

  
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.performanceFilter }) !==
      JSON.stringify({ ...prevState.performanceFilter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchChart();
      }, 100);
    }
  }
}

export default Welcome;