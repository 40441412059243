import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from "../../components/modal";
import Form from "../../components/form"
import {  
  Edit2,
  CheckCircle,
  XCircle
} from "react-feather";

class RequestsLogsFailed extends Component {
  constructor(props){
    super(props)
  this.state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    reverseModal: false,
    // id: "",
    // msisdn: " "
  };
}

 
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Failed Requests'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
          <Filter
            
            dateRange={['startdate', 'enddate']}
            dateRangeLabel='Date Created'
            onChange={(filter) => {
              this.setState({ filter });
              setTimeout(() => {
                this.fetchTransactions();
              }, 0);
            }}
          />
       

            <Table
              search={['account_no','payment_ref']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchRequestFailed();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'Requests',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
             
        <Modal
                    visible={this.state.reverseModal}
                    close={() => this.setState({ reverseModal: false })}>
                    <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

                    <form onSubmit={this.reverseRequest}>
                        <div className="mx-3 d-inline-block mb-3">
                            {/* <span className="ml-1 mb-2 d-block text-capitalize">Id</span> */}
                            <input className="form-control bg-light text-input" name="id" id="id" type="text" value={this.state.id} readOnly="readOnly" hidden="true" onChange={this.handleChange} style={{ width: '400px' }} />
                        </div>
                        <div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Msisdn</span>
                            <input className="form-control bg-light text-input" type="text" name="msisdn" id="msisdn" value={this.state.msisdn} onChange={this.handleChange} style={{ width: '400px' }} />
                        </div>
                        <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                            <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">reverse</button>
                        </div>
                    </form>
                </Modal>
          </div>
        </div>
      </div>
    );
  }

  fetchRequestFailed = () => {
    this.setState({ table_loading: true });
    let { query, filter } = this.state;
    let q = { ...query, ...filter };
   

    if (q.startdate) {
      q.createdAt = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    } 

    window.app
      .service('request-log-failed')
      .find({
        query: {
          ...q,
          msisdn:this.props.user,
          status_id:5,
        },
      })
      .then((response) => {
        response.data = response.data.map((d,i) => {
          return {
           
            "Mpesa Number": d.msisdn,
            "name": d.name,
            "Airtime Number": d.account_no, 
            "payment_ref": d.payment_ref,  
            "response": d.response,         
            "amount": "KES "+parseFloat(d.amount).toLocaleString(),                          
            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
                               
            status: (
              <span
                className={`badge badge-${window.global_status[d.status_id]?.theme
                  }`}>
                {window.global_status[d.status_id]?.name}{' '}
              </span>
            ),           
            
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  reverseRequest = (event) => {
     event.preventDefault();
     let id=this.state.id;
     let data={id:this.state.id,
      msisdn:this.state.msisdn}
  
    console.log(data)
  //  let sdata = JSON.stringify(data);
  //  let obj = JSON.parse(sdata);
  //  let values = Object.values(obj);
  //  let id = obj.id;
  
  //  let postData = data;
   window.app
   .service('request-log-failed')
   .patch(id,data)
   .then((response) => {
     this.setState(response);

     window.alert2.show({
       loader: false,
       icon: <CheckCircle size={60}></CheckCircle>,
       title: 'Transaction reversed Successfully',
       buttons: true,
     });
   })
   .catch((err) => {
     window.alert2.show({
       loader: false,
       icon: <XCircle size={60} className='text-danger'></XCircle>,
       title: 'Error',
       message: err.message,
       buttons: true,
     });
     console.error(err);
   });
};

handleChange = (e) => {
  const { id, value } = e.target
  console.log(e.target.id);
  this.setState(prevState => ({
      ...prevState,
      [id]: value
  }))
}


}

export default RequestsLogsFailed;
