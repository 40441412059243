import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filterall';
import Modal from '../../components/modal';
import Access from '../../components/accessManager';
import ReactJson from 'react-json-view';
const reactStringReplace = require('react-string-replace')

class Customers extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        typeq: '',
        modal: { visible: false },
    };
    timeout = null;
    render() {
        return (
            <div className=''>
                <Nav
                    name='Search Requests By MpesaRef/PhoneNumber'
                ></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Scope',
                                    name: 'type',
                                    options: [
                                        {
                                            label: 'Select Scope',
                                            value: '',

                                        },
                                        {
                                            label: 'Customers',
                                            value: 'customers',

                                        },

                                        {
                                            label: 'Failed Requests',
                                            value: 'request_log_failed',

                                        },
                                        {
                                            label: 'Successful Requests',
                                            value: 'request_log_archive',
                                        },
                                        {
                                            label: 'Payment requests',
                                            value: 'payment_requests',
                                        },




                                        /* {
                                               label: 'mpesa results',
                                               value: 'mpesa_results',
                                               
                                           },
                                         
                                                                                  
   
                                           {
                                               label: 'transaction_requests',
                                               value: 'transaction_requests',
                                           },
                                           {
                                               label: 'Queued Requests',
                                               value: 'request_log',
                                           },*/


                                    ],

                                },

                                {
                                    label: 'Field',
                                    name: 'field',
                                    options: [
                                        {
                                            label: 'Select Field',
                                            value: '',
                                        },
                                        {
                                            label: 'Customer Number',
                                            value: 'msisdn',
                                        },
                                        {
                                            label: 'Transaction Code',
                                            value: 'payment_ref',
                                        },
                                        {
                                            label: 'Mpesa Number',
                                            value: 'msisdn',
                                        },
                                        {
                                            label: 'Account Number',
                                            value: 'account_no',
                                        }


                                    ],

                                },

                                {
                                    label: 'Mpesa Code/PhoneNumber',
                                    name: 'value',
                                    value: '',
                                    search: "true",

                                },
                                // {
                                //     label: 'Mpesa Number ',
                                //     name: 'value',
                                //     value: '',
                                //     search:"true",
                                //     field:"msisdn"



                                // },
                                //  {
                                //     label: 'Account Number',
                                //     name: 'value',
                                //     value: '',
                                //     search:"true",
                                //      field:"account_no"



                                // },


                            ]}
                            onChange={(filter) => {
                                let { query } = this.state;
                                //console.log('shida tubu',filter)

                                this.setState({ query: { ...query, ...filter } });
                                setTimeout(() => {
                                    this.fetchCustomers();
                                }, 0);
                            }}
                        />

                        <Table
                            // search={['PhoneNumber', 'MpesaReceiptNumber']}
                            //sort="created_at"
                            //sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({ query: params });
                                setTimeout(() => {
                                    this.fetchCustomers();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            csv={() => ({
                                name: 'customers',
                                query: { ...this.state.query, ...this.state.filter },
                            })}
                        />
                        {this.state.json && (
                            <Modal
                                visible={true}
                                close={() => {
                                    this.setState({ json: null });
                                }}>
                                <ReactJson
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    src={this.state.json}
                                />
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    fetchCustomers = () => {
        this.setState({ table_loading: true });

        let { query, filter } = this.state;
        let q = { ...query, ...filter };
        //console.log('query is is',query);
        //console.log('filter is is',filter)

        // if (q.startdate) {
        //   q.last_activity_date = {
        //     $gte: q.startdate,
        //     $lte: q.enddate,
        //   };
        //   delete q.startdate;
        //   delete q.enddate;
        // }

        console.log(q)
        this.setState({ tableData: { data: [] }, })
        window.app
            .service('api/query')
            .find({
                query: {
                    ...q
                    //   agent_id:this.props.user,
                    //   user_type:2
                },
            })
            .then((response) => {
                function showSearched(data, value) {
                    if ((new RegExp(value, "g").test(data))) {

                        return (
                            <div>
                                {reactStringReplace(data.replace(new RegExp(value, "g"), "###"), '###', (match, i) => (
                                    <span style={{ "background-color": "yellow" }}>{value}</span>
                                ))}
                            </div>
                        );
                    } else {
                        return data
                    }

                }

                response.data = response.data.map((d) => {
                    if (response.type === 'customers') {

                        let addon;
                        d.addOns && (Object.keys(d.addOns).forEach((key) => {
                            addon = d.addOns[key];
                        }))
                        console.log('addon is', addon)


                        return {

                            addOns: d.addOns && (
                                <button
                                    className='btn btn-sm btn-outline-success font-weight-bold'
                                    onClick={() =>
                                        this.setState({ json: d })
                                    }>
                                    view
                                </button>
                            ),
                            name: d.cust_name,
                            phone: d.msisdn,
                            commission: "KES " + parseFloat(d.commission).toLocaleString(),
                            net_value: "KES " + parseFloat(d.net_value).toLocaleString(),
                            unit_consumed: "KES " + parseFloat(d.unit_consumed).toLocaleString(),
                            points: d.points,
                            user_type: (
                                <span
                                    className={`badge badge-${window.userTypes[d.user_type]
                                        }`}>
                                    {window.userTypes[d.user_type]?.user_type}{' '}
                                </span>
                            ),
                            status: (
                                <span
                                    className={`badge badge-${window.global_status[d.status].theme
                                        }`}>
                                    {window.global_status[d.status]?.name}{' '}
                                </span>
                            ),
                            last_activity_date: moment(d.last_activity_date).format('YYYY-MM-DD hh:mm:ss'),
                            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),


                            action: (
                                <div>
                                    <Access permission='CUSTOMER_DETAILS_READ'>
                                        <Link
                                            className='btn btn-sm btn-round btn-outline-primary px-3'
                                            to={`/customers/${d.id}/details`}>
                                            View
                                        </Link>
                                    </Access>

                                    {window.user.admin['group']['name'] != 'CCR' &&
                                        (<button
                                            onClick={() => {
                                                this.setState({ updateModal: true, activeDetails: d, addOns: addon, id: d.id });

                                            }}
                                            className="btn btn-sm btn-round btn-outline-primary px-3"
                                        >
                                            Update
                                        </button>)}
                                </div>


                            ),





                        };

                        // return {
                        //     "ShortCode": d.ShortCode,
                        //     "ShortCodeType": d.ShortCodeType,
                        //     "MpesaReceiptNumber": showSearched(d.MpesaReceiptNumber,q.value),
                        //     "PhoneNumber": showSearched(d.PhoneNumber,q.value),
                        //     "Amount": d.Amount,
                        //     "name": d.name,
                        //     "date_created":moment.utc(d.createdAt).format("DD MMMM, YYYY.  HH:mm:ss"),
                        // };
                    }
                    else if (response.type === 'mpesa_results') {
                        return {
                            "ShortCode": d.ShortCode,
                            "ShortCodeType": d.ShortCodeType,
                            "MpesaReceiptNumber": showSearched(d.MpesaReceiptNumber, q.value),
                            "PhoneNumber": showSearched(d.PhoneNumber, q.value),
                            "Amount": d.Amount,
                            "name": d.name,
                            "date_created": moment.utc(d.createdAt).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };
                    } else if (response.type === 'payment_requests') {
                        return {
                            "description": d.description,
                            "interface": d.interface,
                            "msisdn": showSearched(d.msisdn, q.value),
                            "amount": showSearched(d.amount, q.value),
                            "ack_payment_reference": d.ack_payment_reference,
                            response: (
                                <button
                                    className="btn px-2 badge badge-info"
                                    onClick={() => {
                                        this.setState({ json: d });
                                    }}
                                >
                                    View
                                </button>
                            ),

                            response_status: (
                                <span
                                    className={`badge badge-${window.global_status[d.response_status]?.theme
                                        }`}>
                                    {window.global_status[d.response_status]?.name}{' '}
                                </span>
                            ),
                            "date_created": moment.utc(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };

                    } else if (response.type === 'transaction_requests') {
                        return {
                            "status_id": d.status_id,
                            "reference_id": d.reference_id,
                            "initiator_type": d.initiator_type,
                            "initiator_id": showSearched(d.initiator_id, q.value),
                            "denomination_value": d.denomination_value,
                            "account_no": showSearched(d.account_no, q.value),
                            "provider": "SAFARICOM",
                            response: (
                                <button
                                    className="btn px-2 badge badge-info"
                                    onClick={() => {
                                        this.setState({ json: d });
                                    }}
                                >
                                    View
                                </button>
                            ),
                            "date_created": moment.utc(d.createdAt).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };

                    } else if (response.type === 'request_log') {
                        return {

                            "msisdn": showSearched(d.msisdn, q.value),
                            "name": d.name,
                            "account_no": showSearched(d.account_no, q.value),
                            "payment_ref": showSearched(d.payment_ref, q.value),
                            "amount": d.amount,
                            "provider": d.provider,
                            response: (
                                <button
                                    className="btn px-2 badge badge-info"
                                    onClick={() => {
                                        this.setState({ json: d });
                                    }}
                                >
                                    View
                                </button>
                            ),
                            "date_created": moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };


                    } else if (response.type === 'request_log_archive') {
                        return {
                            "msisdn": showSearched(d.msisdn, q.value),
                            "name": d.name,
                            "account_no": showSearched(d.account_no, q.value),
                            "payment_ref": showSearched(d.payment_ref, q.value),
                            "amount": d.amount,
                            "provider": d.provider,
                            response: (
                                <button
                                    className="btn px-2 badge badge-info"
                                    onClick={() => {
                                        this.setState({ json: d });
                                    }}
                                >
                                    View
                                </button>
                            ),
                            "date_created": moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
                            "date_modified": moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };
                    } else if (response.type === 'request_log_failed') {
                        return {
                            "msisdn": showSearched(d.msisdn, q.value),
                            "name": d.name,
                            "account_no": showSearched(d.account_no, q.value),
                            "payment_ref": showSearched(d.payment_ref, q.value),
                            "amount": d.amount,
                            "provider": d.provider,
                            response: (
                                <button
                                    className="btn px-2 badge badge-info"
                                    onClick={() => {
                                        this.setState({ json: d });
                                    }}
                                >
                                    View
                                </button>
                            ),
                            "date_created": moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
                        };
                    }else{
                        return d
                    }
                    //else if (response.type === 'mpesa_stk') {
                    //     return {
                    //         msisdn: d.msisdn,
                    //         shortCode: (<code>{d.shortCode}</code>),
                    //         mpesa_type: d.mpesa_type,
                    //         resultCode: d.ResultCode,
                    //         ResultDescription: d.ResultDescription,
                    //         MerchantRequestID: d.MerchantRequestID,
                    //         CheckoutRequestID: d.CheckoutRequestID,
                    //         ResponseCode: d.ResponseCode,
                    //         ResponseDescription: d.ResponseDescription,
                    //         date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
                    //     };
                    // }


                });

                this.setState({ tableData: response, table_loading: false });
            })
            .catch((err) => {
                this.setState({ table_loading: false });
                console.error(err);
            });
    };
}

export default Customers;
