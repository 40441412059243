import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filteralldatepicker';
import Access from '../../components/accessManager';

class Topups extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Customers'
          buttons={[
            {
              text: 'Add a Customer',
              link: '/customer/create',
              permission: 'CUSTOMER_CREATE',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>

            <Filter  
              getFilter={filter => {
                 this.setState({
                 filter
               }); 
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
           
           dateRange={["startdate", "enddate"]}
          
         />
        

            <Table
              search={['msisdn', 'account_no']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    let { query, filter } = this.state;
    let q = { ...query, ...filter };

    if (q.startdate) {
      q.date_created = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    window.app
      .service('request-log-archive')
      .find({
        query: {
          ...q,
          user_id:this.props.user
        
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
              "ClientId":d.user_id,           
            "MpesaNumber": d.msisdn,
            "name": d.name,          
            "AirtimeNumber": d.account_no, 
             "payment_ref": d.payment_ref,  
            "response": d.response,                    
            "amount": "KES "+parseFloat(d.amount).toLocaleString(),   
            "provider": d.provider,                          
            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
            
         
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Topups;
