import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Access from './accessManager';

let access = new Access();

class Tabs extends Component {
  state = { titles: [], active: 0 };
  render() {
    return (
      <div className='tabs'>
        <div className='tab-links border-bottom d-flex flex-row bg-white shadow-sm'>
          {this.state.titles.map((d, i) => {
            let allowed = true;
            if (d.permission) {
              allowed = access.verify(d.permission);
            }
            if (allowed)
              return (
                <Link
                  key={i}
                  className={
                    'tab-link p-3 d-flex text-center flex-fil ' +
                    (this.state.active === i && 'active')
                  }
                  onClick={() => {
                    this.setState({ active: i });
                  }}
                  to={d.link}>
                  <span className='position-relative'>{d.title}</span>
                </Link>
              );
          })}
        </div>

        <div className='pt-3'>{this.props.children}</div>
      </div>
    );
  }

  componentDidMount() {
    let titles = this.props.children.map((d) => {
      return {
        title: d.props.title,
        link: d.props.path,
        permission: d.props.permission,
      };
    });
    this.setState({ titles });
    console.log(this.props.children);
  }
}

export default Tabs;
