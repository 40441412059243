import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import {
    Plus,
    Target,
    Circle,
    Smartphone,
    Edit2,
    RefreshCw,
    PlusSquare, CheckCircle, XCircle
} from "react-feather";



import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import Access from "../../components/accessManager";

class SmsBlast extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
        <div className="">
          <Nav
              name="SmsBlast"
              buttons={[
                {
                  text: "Create Blast",
                  onClick: () => {
                    this.setState({ addModal: true });
                  },
                  access: "BLAST_CREATE"
                }
              ]}
          ></Nav>

          <div className="mt-3 table-card p-4 border-0 card shado mx-3 shadow">
              <Filter
                  filter={[

                  ]}
                  dateRange={['startdate', 'enddate']}
                  dateRangeLabel='Date Range'
                  onChange={(filter) => {
                      let { query } = this.state;

                      this.setState({ query: { ...query, ...filter } });
                      setTimeout(() => {
                          this.fetchCustomers();
                      }, 0);
                  }}
              />
            <Table
                search={["id","command_type_id","message","mobile"]}
                // sort="created_at"
                // sortDirection={-1}
                refreshAllowed={true}
                data={this.state.tableData}
                fetch={params => {
                  this.setState({ query: params });
                }}
                loading={this.state.table_loading}
                fetchError={this.state.tableError}
            />
          </div>


          <Modal
              width
              visible={this.state.addModal}
              close={() => this.setState({ addModal: false })}
          >
            <div className="d-flex flex-row align-items-center p4">
              <PlusSquare className="mr-3"></PlusSquare>
              <h5 className="m-0 mt-1">Create a Blast</h5>
            </div>
            <div className="mt-3">
              <Form
                  inputs={[
                      {
                          label: 'Command',
                          name: 'command_type_id',
                          type: 'select',
                          required:"true",
                          options: window?.command_types?.filter(d=>d.scope.includes('blast')).map((d) => {
                              return {
                                  name: d.name,
                                  value: d.id,
                              };
                          }),
                      },

                      {
                      label: "Mobile",
                      name: "mobile",
                      type: "text",
                      value: ""
                    },
                    {
                      label: "Message",
                      name: "message",
                      type: "textarea",
                      value: ""
                    },
                  ]}
                  submit={data => {
                    console.log(data);
                    this.setState({ details: data });
                    setTimeout(() => {
                      this.add(data);
                    }, 0);
                  }}
              />
            </div>
          </Modal>
        </div>
    );
  }


    fetchSmsBlast = () => {
        this.setState({ table_loading: true });

        window.app
            .service('blast')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d,i) => {
                    return {
                        id: d.id,
                        command: (<code>[{d.command_type_id}]<b>{d.command_type?.name}</b></code>),
                        mobile: d.mobile,
                        message: d.message,
                        processed:d.processed,
                        date_created:d.dateCreated && moment(d.dateCreated).format('YYYY-MM-DD hh:mm:ss'),
                        date_processed:d.dateProcessed && moment(d.dateProcessed).format('YYYY-MM-DD hh:mm:ss'),
                    };
                });

                this.setState({ tableData: response, table_loading: false });
            })
            .catch((err) => {
                this.setState({ table_loading: false });
                console.error(err);
            });
    };



  componentDidUpdate(prevProps, prevState) {
    if (
        JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
        JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchSmsBlast();
      }, 100);
    }
  }

  add = data => {
    if (!window.confirm("Are you sure that you want add this Entry?"))
      return false;


      window.app
          .service('blast')
          .create(data)
          .then((response) => {
              this.setState(response);

              window.alert2.show({
                  loader: false,
                  icon: <CheckCircle size={60}></CheckCircle>,
                  title: 'Blast Created and Queued Successfully',
                  buttons: true,
              });
              this.setState({ addModal: false })
          })
          .catch((err) => {
              window.alert2.show({
                  loader: false,
                  icon: <XCircle size={60} className='text-danger'></XCircle>,
                  title: 'Error',
                  message: err.message,
                  buttons: true,
              });
              console.error(err);
          });
  };


}

export default SmsBlast;
