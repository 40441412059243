import React, { Component } from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import TextareaCounter from 'react-textarea-counter';
import {v4 as uuidv4} from 'uuid';




class AdminCreate extends Component {
  state = {ApiKey:uuidv4()};
  render() {
  
    return (
      
      <div className=''>
        <Nav name='Create Service Client'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
          <div className='order-form'>
          <form onSubmit={this.createClient}>
              <div className="row">
                  <div className="col-lg-5">
                   <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">client_ref_id:</span>
                  {/* <input className="form-control bg-light text-input" name="service_ref_id" id="service_ref_id" type="text" initialValue={this.state.service_ref_id} onChange={this.handleChange} style={{ width: '400px' }} />                */}
                  <select className="form-control bg-light text-input" name="client_ref_id" id="client_ref_id" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>
                  <option value=''>Select Option</option>
                  {window.clients.map((d)=>{
                    return<option value={d.id}>{d.CompanyName}</option>
                  })}
          
                   </select>
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">service_ref_id:</span>
                  {/* <input className="form-control bg-light text-input" name="service_ref_id" id="service_ref_id" type="text" initialValue={this.state.service_ref_id} onChange={this.handleChange} style={{ width: '400px' }} />                */}
                  <select className="form-control bg-light text-input" name="service_ref_id" id="service_ref_id" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>
                  <option value=''>Select Option</option>
                  {window.services.map((d)=>{
                    return<option value={d.id}>{d.name}</option>
                  })}
          
                   </select>
                </div>
                
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">ClientId:</span>
                  <input className="form-control bg-light text-input" name="ClientId" id="ClientId" type="text" initialValue={this.state.ClientId} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">ApiKey:</span>
                  <input className="form-control bg-light text-input" name="ApiKey" id="ApiKey" type="text" Value={this.state.ApiKey} readOnly="ReadOnly" style={{ width: '400px' }} />               
                </div> 
                 </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-5">
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">is_live:</span>                  
                  <select className="form-control bg-light text-input" name="is_live" id="is_live" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>            
                  <option value=''>Select Option</option>
                    <option value='1'>True</option>
                    <option value='0'>False</option>
                   </select>
                  </div>
                  
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">is_active:</span>                  
                  <select className="form-control bg-light text-input" name="is_active" id="is_active" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>            
                  <option value=''>Select Option</option>
                    <option value='1'>True</option>
                    <option value='0'>False</option>
                   </select>
                  </div>
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Percentage:</span>
                  <input className="form-control bg-light text-input" name="percentage_commission" id="percentage_commission" type="text" initialValue={this.state.percentage_commission} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                  </div>
               
                   <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Create</button>
                </div>

                
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  createClient = (event) => {
    event.preventDefault();
   
    let data = {
      client_ref_id: this.state.client_ref_id,
      service_ref_id:this.state.service_ref_id,
      ClientId: this.state.ClientId,
      ApiKey:this.state.ApiKey,
      is_live: this.state.is_live,
      is_active:this.state.is_active, 
      priority_id:1,
      live_activator_id:1,
      units:this.state.units,
      percentage_commission:this.state.percentage_commission, 
       
    };
    if (
        !window.confirm("Are you sure that you want to create Service Client?")
    )
        return false;

      console.log(data)

    window.app
        .service('service-clients')
        .create(data)
        .then((response) => {
            this.setState(response);

            window.alert2.show({
                loader: false,
                icon: <CheckCircle size={60}></CheckCircle>,
                title: 'Service Client Added Successfully',
                buttons: true,
            });
            window.location = '/reseller';
        })
        .catch((err) => {
            window.alert2.show({
                loader: false,
                icon: <XCircle size={60} className='text-danger'></XCircle>,
                title: 'Error',
                message: err.message,
                buttons: true,
            });
            console.error(err);
        });

};
handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
        ...prevState,
        [id]: value
    }))
}
}

export default AdminCreate;
