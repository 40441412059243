import React, { Component } from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';


class AdminCreate extends Component {
  state = {};
  render() {
    return (
      <div className=''>
        <Nav name='Buy Airtime'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
          <div className='order-form'>
            <Form
              inputs={[
                {
                  label: 'name',
                  name: 'name',
                  type: 'text',
                },
                {
                  label: 'Phone Number',
                  name: 'msisdn',
                  type: 'tel',
                },
                {
                  label: 'email',
                  name: 'email',
                  type: 'email',
                },
                {
                  label: 'password',
                  name: 'password',
                  type: 'password',
                },
                {
                  label: 'secondFactor selected',
                  name: 'secondFactor_selected',
                  type: 'select',
                  options: [
                    {
                      name: 'SMS',
                      value: 'SMS',
                    },
                  ],
                },

                {
                  label: 'secondFactor Status',
                  name: 'secondFactor_status',
                  type: 'select',
                  options: [
                    {
                      name: 'Enabled',
                      value: 0,
                    },
                    {
                      name: 'Disabled',
                      value: 1,
                    },
                  ],
                },

                {
                  label: 'status',
                  name: 'status',
                  type: 'select',
                  options: [
                    {
                      name: 'Active',
                      value: 1,
                    },
                    {
                      name: 'Inactive',
                      value: 0,
                    },
                  ],
                },

                {
                  label: 'group',
                  name: 'group_id',
                  type: 'select',
                  options: window.groups.map((d) => {
                    return {
                      name: d.name,
                      value: d.id,
                    };
                  }),
                },
              ]}
              submit={(data) => {
                this.createCustomer(data);
              }}
            ></Form>
          </div>
        </div>
      </div>
    );
  }

  createCustomer = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Buy Airtime ...',
      buttons: false,
    });

    window.app
      .service('api/airtime/request')
      .create(data)
      .then((response) => {
        this.setState(response);

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Airtime purchased Successfully',
          buttons: true,
        });
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
        console.error(err);
      });
  };
}

export default AdminCreate;
