import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
// import Details from "./clients/details";
import {
  ThumbsUp,
  StopCircle,
  RefreshCw,
  DollarSign,
  XCircle,
  Trash,
  LogOut,
  FileText,
  CheckCircle,
  Info,
  UserPlus,
} from 'react-feather';
import Tabs from '../../components/tabs';
import UserSummarry from './summarry';
import Transactions from '../finance/transactions';
import Withdrawals from '../finance/userwithdrawals';
import Deposits from '../mpesa/userc2b_results';
import Form from '../../components/form';
import Modal from '../../components/modal';
import Customerlogs from '../users/usercustomersLogs';
import Requestlogarchive from '../requests/userrequestsLogArchive';
import Requestlogfailed from '../requests/userrequestsLogFailed';
import Requestlogreversed from '../requests/userrequestsLogReversed';
import Dbqueue from '../messages/userdqueue';
import Access from '../../components/accessManager';
import Stkrequests from '../mpesa/userstk';

class CustomerDetails extends Component {
  state = { updateModal: false,};
  render() {
    let user = [];

    return (
      <div className='bg-light'>
        <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
          <div className='text-mute pt-3 pl-3'>
            <small className='text-mute'>Customers &gt; View</small>
          </div>


          <Modal
              visible={this.state.updateModal}
              close={() => this.setState({ updateModal: false, errors: {} })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.updatepoints}>               
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Points:</span>
                  <input className="form-control bg-light text-input" name="points" id="points" type="text" value={this.state.points} onChange={this.handleChange} style={{ width: '400px' }} />
                  
                </div>

               
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
                </div>
              </form>
            </Modal>


          

          <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
            <div className='d-md-flex flex-row align-items-center'>
              <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <span className='initials'>
                  {/* {this.state.cust_name && this.state.cust_name[0]} */}
                  <h4 className="text-capitalize">
                  {
                  this.state.cust_name && this.state.cust_name.substring(0,1)
                  }
                  </h4>
                </span>
                {/* <img
                  src={`${window.fileserver}${this.state.avatar}`}
                  className='avatar'
                  alt=''
                /> */}
              </div>
              <div className='ml-md-4 my-3 my-md-0'>
                <h4 className='text-capitalize'>
                  {this.state.cust_name} {this.state.cust_name}
                </h4>
                <div>{this.state.msisdn}</div>
                <div className='ml-2 mt-1'>
                  <span className='badge badge-secondary px-2'>Customer</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
              <button 
                 onClick={this.blacklist}
                className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <UserPlus size={18} />
                  <span className="pl-1 font-weight-bold">Add to Blacklist</span>
                </button>                                 
              </div>

              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
              <button 
                 onClick={() => {
                  this.setState({ updateModal: true});

                }}
                className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <UserPlus size={18} />
                  <span className="pl-1 font-weight-bold">Update Points</span>
                </button>                                 
              </div>
            </div>

            {/* <div className='d-flex flex-row flex-wrap member-view '>
              <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
              
              </div>
            </div> */}
          </div>
        </div>
        <div className='position-relative px-3'>
          {this.state.msisdn && (
            <Tabs>
              <Route
                permission='all'
                path={`/customers/${this.props.match.params.id}/details`}
                title='Details'>
                <UserSummarry data={this.state}></UserSummarry>
              </Route>
              <Route
                permission='USERCUSTOMER_LOGS'
                path={`/customers/${this.props.match.params.id}/customerlogs`}
                title='Commission Statement'>
                <Customerlogs user={this.state.id}></Customerlogs>
              </Route>

              <Route
                permission='USERTRANSACTIONS_READ'
                path={`/customers/${this.props.match.params.id}/transactions`}
                title='Ussd Requests'>
                <Transactions user={this.state.msisdn}></Transactions>
              </Route> 
              <Route
                permission='USERREQUESTLOGARCHIVE_READ'
                path={`/customers/${this.props.match.params.id}/requestlogarchive`}
                title='Successful Requests'>
                <Requestlogarchive user={this.state.msisdn}></Requestlogarchive>
              </Route>   
              <Route
                permission='USERREQUESTLOGFAILED_READ'
                path={`/customers/${this.props.match.params.id}/requestlogfailed`}
                title='Failed Requests'>
                <Requestlogfailed user={this.state.msisdn}></Requestlogfailed>
              </Route> 
              <Route
                permission='USERREQUESTLOGREVERSED_READ'
                path={`/customers/${this.props.match.params.id}/requestlogreversed`}
                title='Reversed Requests'>
                <Requestlogreversed user={this.state.msisdn}></Requestlogreversed>
              </Route>
              <Route
                permission='USERREQUESTSTKREQUESTS_READ'
                path={`/customers/${this.props.match.params.id}/stkrequests`}
                title='Stk Requests'>
                <Stkrequests user={this.state.msisdn}></Stkrequests>
              </Route> 
              <Route
                permission='USERREQUESTC2BCONFIRMATION_READ'
                path={`/customers/${this.props.match.params.id}/c2bconfirmation`}
                title='C2B'>
                <Deposits user={this.state.msisdn}></Deposits>
              </Route>  
              <Route
                permission='USERREQUESTB2CCONFIRMATION_READ'
                path={`/customers/${this.props.match.params.id}/b2cconfirmation`}
                title='B2C'>
                <Withdrawals user={this.state.id}></Withdrawals>
              </Route> 

              <Route
                permission='USERMESSAGE_READ'
                path={`/customers/${this.props.match.params.id}/sms`}
                title='SMS'>
                <Dbqueue msisdn={this.state.msisdn}></Dbqueue>
              </Route>

             
            </Tabs>
          )}
        </div>

      </div>
    );
  }

  componentDidMount = () => {
    this.fetchCustomer();
  };

  fetchCustomer = () => {
    window.app
      .service('customer')
      .get(this.props.match.params.id)
      .then((response) => {
        this.setState(response);
      });
  };

  updateCustomer = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Updating Customer ...',
      buttons: false,
    });

    window.app
      .service('customers')
      .patch(this.state.id, data)
      .then((response) => {
        window.alert2.show({
          loader: false,
          msisdn: this.state.msisdn,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Updated Successfully',
          buttons: true,
          onSubmit: () => {
            this.props.history.push('/customers/' + response.id + '/details');
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.code !== 500) {
          window.alert2.show({
            loader: false,
            icon: <Info size={60}></Info>,
            title: 'MakerChecker Alerts',
            message: err.message,
            buttons: true,
            onSubmit: () => {
              window.location.reload();
            },
          });

          return;
        }

        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };

  updateWithdrawals = () => {
    window.alert2.show({
      loader: true,
      title: 'Reconciling ...',
      buttons: false,
    });

    window.app
      .service('recon')
      .create({})
      .then((response) => {
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Reconciled Successfully',
          buttons: true,
          onSubmit: () => {
            window.location.reload();
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };
  blacklist = () => {
    if (
      !window.confirm("Are you sure that you want to blacklist msisdn "+this.state.msisdn+"?")
    )
    return false;
    let id=this.state.id;
    let data = { status: 7 }
    console.log(id)
    window.app
    .service('customers')
    .patch(id, data)
    .then((response) => {
      this.setState(response);

      window.alert2.show({
        loader: false,
        icon: <CheckCircle size={60}></CheckCircle>,
        title: 'Customer Blacklisted Successfully',
        buttons: true,
      });
     this.refreshTable('default');
      
    })
    .catch((err) => {
      window.alert2.show({
        loader: false,
        icon: <XCircle size={60} className='text-danger'></XCircle>,
        title: 'Error',
        message: err.message,
        buttons: true,
      });
      console.error(err);
    });

  };

  handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }



  updatepoints = (event) => {
    event.preventDefault();
      

      let id = this.state.id;
      let data = {
        id: this.state.id,
       points: this.state.points,      
      };
      if (
        !window.confirm("Are you sure that you want to update points?")
      )
        return false;

      // console.log(data)

      window.app
        .service('customer')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Customer updated Successfully',
            buttons: true,
          });
          // this.refreshTable('default');
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
 
  };


 

}

export default CustomerDetails;
