import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Modal from '../../components/modal';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import moment from 'moment';
import Filter from '../../components/filter';
import ReactJson from 'react-json-view';

class Page extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    modal: { visible: false },
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>


        <Nav
          name='Payment Requests'
        />
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              search={['ack_payment_reference', 'interface', 'msisdn', 'reference', 'response_txt',]}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetch();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
        </div>

      </div>
    );
  }

  fetch = () => {
    this.setState({ table_loading: true });

    window.app
      .service('payments/logs')
      .find({
        query: {
          ...this.state.query,
          payment_request_id: this.props.paymentRequest?.id,
        },
      })
      .then((response) => {
        response.data = response.data.map((d, i) => {
          return {
            // id: d.id,
            interface: d.interface,
            amount: d.amount,
            msisdn: d.msisdn,
            reference: d.reference,
            ack_ref: (<code>{d.ack_payment_reference}</code>),
            level: d.level,
            fulfilment_message: JSON.parse(d.response_txt)?.message,            
            "date_created":moment.utc(d.createdAt).format("DD MMMM, YYYY.  HH:mm:ss"),
            status: d.response_status,
            response: (
              <button
                className="btn px-2 badge badge-info"
                onClick={() => {
                  this.setState({ json: d });
                }}
              >
                View
              </button>
            ),

          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };


}

export default Page;