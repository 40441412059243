import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Dealers'
          buttons={[
            {
              text: 'Add a Customer',
              link: '/customer/create',
              permission: 'CUSTOMER_CREATE',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
          <Filter
              filter={[
                {
                  label: 'Commission',
                  name: 'commission',
                  options: [
                    {
                      label: 'All',
                      value: 0,
                      default: true,
                    },
                    {
                      label: 'More than 60,000',
                      value: 60000,
                    },
                    {
                      label: 'More than 140,000',
                      value: 140000,
                    },
                  ],
                },
                {
                  label: 'Net Value',
                  name: 'net_value',
                  options: [
                    {
                      label: 'All',
                      value: 0,
                      default: true,
                    },
                    {
                      label: 'More than 10,000',
                      value: 10000,
                    },
                    {
                      label: 'More than 50,000',
                      value: 50000,
                    },
                  ],
                },
              ]}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              onChange={(filter) => {
                let { query } = this.state;

                this.setState({ query: { ...query, ...filter } });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
            />

            <Table
              search={['msisdn', 'cust_name']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'dealers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    let { query } = this.state;

    window.app
      .service('customer')
      .find({
        query: {
          ...this.state.query,         
          user_type:3
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
           name: d.cust_name,
            phone: d.msisdn,           
            commission: "KES "+parseFloat(d.commission).toLocaleString(), 
            net_value: "KES "+parseFloat(d.net_value).toLocaleString(),
            unit_consumed: "KES "+parseFloat(d.unit_consumed).toLocaleString(),       
            points: d.points,
            user_type: (
              <span
                className={`badge badge-${
                  window.userTypes[d.user_type]
                }`}>
                {window.userTypes[d.user_type]?.user_type}{' '}
              </span>
            ),
            status: (
              <span
                className={`badge badge-${
                  window.global_status[d.status].theme
                }`}>
                {window.global_status[d.status]?.name}{' '}
              </span>
            ),
          //   status: (<span
          //     className={`badge badge-${window.global_status[d.status]?.theme
          //         }`}>
          //     {window.global_status[d.status]?.desc}{}
          // </span>),
            // agent_id:d.agent_id,
                       
            last_activity_date: moment(d.last_activity_date).format('YYYY-MM-DD hh:mm:ss'),        
            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
            action: (
              <Access permission='CUSTOMER_DETAILS_READ'>
                <Link
                  className='btn btn-sm btn-round btn-outline-primary px-3'
                  to={`/dealer/${d.id}/details`}>
                  View
                </Link>
              </Access>
            ),
            
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Customers;
