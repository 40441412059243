import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Service clients'
          buttons={[
            {
              text: 'Add Service Client',
              link: '/serviceclient-create',
              permission: 'AGENT_READ',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
         

            <Table
              search={['ClientId','ApiKey']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'resellers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    let { query } = this.state;

    window.app
      .service('service-clients')
      .find({
        query: {
          ...this.state.query,
          client_ref_id:this.props.match.params.id         
          
        },
      })
      .then((response) => {
        console.log("response is is",response)
        response.data = response.data.map((d) => {
          return {
              id:d.id,
            ClientId: d.ClientId,
            ApiKey:d.ApiKey,
            Balance:d.units,
            minimum_units:d.minimum_units,
            percentage_commission:d.percentage_commission,
            Activestatus: (<span
              className={`badge badge-${window.global_state[d.is_active].theme
                  }`}>
              {window.global_state[d.is_active].desc}
          </span>),
           LiveStage: (<span
            className={`badge badge-${window.global_live[d.is_live].theme
                }`}>
            {window.global_live[d.is_live].desc}
        </span>),


          
            // status: (
            //   <span
            //     className={`badge badge-${
            //       window.global_status[d.is_active].theme
            //     }`}>
            //     {window.global_status[d.is_active]?.name}{' '}
            //   </span>
            // ),     
                       
            created_at: moment(d.created_at).format('YYYY-MM-DD hh:mm:ss'),        
            updated_at: moment(d.updated_at).format('YYYY-MM-DD hh:mm:ss'),
            action: (
              <Access permission='AGENT_READ'>
                <Link
                  className='btn btn-sm btn-round btn-outline-primary px-3'
                  to={`/reseller/${this.props.match.params.id}/${d.id}/${d.units}/details`}>
                  View
                </Link>
              </Access>
            ),
            
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Customers;
