import React, { Component } from 'react';
import Nav from '../components/Nav';

class Dashboards extends Component {
  state = {
    panels: {
      tp10: {
        name: 'Ussd Sessions',
        graphs: [
          {
            name: 'Ussd Sessions',
            url:
              window.grafanaServer +
              '/d-solo/zNrUEnPGz/ussd?orgId=1&refresh=5s&panelId=2',
          },
          
          {
            name: 'Hourly Ussd Sessions',
            url:
              window.grafanaServer +
              '/d-solo/zNrUEnPGz/ussd?orgId=1&refresh=5s&panelId=3',
          }, 
        ],
      },

    },
    activePanel: 0,
  };
  render() {
    return (
      <div>
        <Nav name={this.state.panels[this.props.match.params.type].name}></Nav>
        <div className='row p-4'>
          {this.state.panels[this.props.match.params.type].graphs.map(
            (d, i) => (
              <div className={d.size?d.size:'col-md-6 my-3'} key={i}>
                <div className='card bg-white p-3'>
                  <iframe
                    title='123'
                    src={d.url + '&theme=light'}
                    className='w-100'
                    height={d.height?d.height==-1?"auto":d.height:"400"}
                    frameBorder={0}
                  ></iframe>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default Dashboards;
