import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Grid,
  User as UsersIcon,
  DollarSign,
  Target,
  Menu as MenuBars,
  UserCheck,
  FileText,
  Users,
  Upload,
  LogOut,
  Settings,
  ChevronDown,
  ChevronUp,
  MapPin,
  MessageSquare,
  Shield,
  CreditCard,
  Code,
  Smartphone,
  Calendar,
  MessageCircle,
  Bell,
  Gift,

} from 'react-feather';
import Access from './accessManager';
import user from '../img/user.svg';
import { getFirstPartOfHostname } from '../components/phonevalidate';

class Menu extends Component {
  state = {
    title: 'Credo',
    menu: [
      {
        name: 'Dashboard',
        icon: <Grid size={20} />,
        link: '/',
        access: 'all',
        children: [
          {
            name: 'Welcome',
            link: '/',
            access: 'all',
          },
          {
            name: 'Home',
            link: '/home',
            access: 'DASHBOARD_READ',
          },
          {
            name: 'Ussd Sessions',
            link: '/dashboards/tp10',
            access: 'MPESA_STK_READ',
          },



        ],
      },
      {
        name: 'Customers',
        icon: <Users size={20} />,
        link: '/customers',
        access: 'CUSTOMER_READ',
      },

      {
        name: 'Requests',
        icon: <Calendar size={20} />,
        link: '/request-log-archive',
        access: 'REQUEST_READ',
        children: [

          {
            name: 'Successful Requests',
            link: '/request-log-archive',
            access: 'REQUEST_READ',
          },
          {
            name: 'Failed Requests',
            link: '/request-log-failed',
            access: 'REQUEST_READ',
          },
          {
            name: 'Reversed Requests',
            link: '/request-log-reversed',
            access: 'REQUEST_READ',
          },

          {
            name: 'Commission Statement',
            icon: <UserCheck size={20} />,
            link: '/customer-logs',
            access: 'CUSTOMER_LOGS',
          },
          {
            name: 'Ussd Requests',
            icon: <Shield size={20} />,
            link: '/transactions',
            access: 'REQUEST_READ',
          },


        ],
      },
      {
        name: 'Payments',
        icon: <Grid size={20} />,
        link: '/payment_requests',
        access: 'MPESA_C2BRESULTS_READ',
        children: [
          {
            name: 'Payment Requests',
            icon: <Settings size={20} />,
            link: '/payment_requests',
            access: 'PAYMENT_REQUEST_READ',
          },
          { type: "hr", title: "Recon" },

          {
            name: 'Pull Results',
            link: '/pull-results',
            access: 'TRANSACTION_READ',
          },
          {
            name: 'Pull Requests',
            link: '/pull-requests',
            access: 'TRANSACTION_READ',
          },
          {
            name: "Upload failed Deposits",
            link: "/deposits-c2b",
            access: "TRANSACTION_READ"
          },
          { type: "hr", title: "Mpesa" },

          {
            name: 'STK',
            link: '/mpesa/stk',
            access: 'MPESA_STK_READ',
          },
          {
            name: 'C2B Results',
            link: '/mpesa/c2b_results',
            access: 'MPESA_C2BRESULTS_READ',
          },
          {
            name: 'B2C Results',
            link: '/withdrawals',
            access: 'WITHDRAWALS_READ',
          },
          { type: "hr", title: "AirtelMoney" },

          {
            name: 'STK',
            link: '/airte1_money/stk',
            access: 'AIRTEL_MONEY_STK_READ',
          },
          {
            name: 'C2B Results',
            link: '/airte1_money/c2b_results',
            access: 'AIRTEL_MONEY_C2BRESULTS_READ',
          }



        ],
      },
      {
        name: 'Search',
        icon: <Bell size={20} />,
        link: '/api',
        access: 'SEARCH_READ',
      },


      {
        name: 'All Queries',
        icon: <Bell size={20} />,
        link: '/api',
        access: 'TRANSACTION_READ',
        children: [
          {
            name: 'Search',
            icon: <Bell size={20} />,
            link: '/api',
            access: 'TRANSACTION_READ',
          },


        ],
      },
      {
        name: 'Transactions',
        icon: <Bell size={20} />,
        link: '/successful',
        access: 'TELCOM_READ',
        children: [
          {
            name: 'Successful',
            icon: <Bell size={20} />,
            link: '/successful',
            access: 'TELCOM_READ',
          },
          // {
          //   name: 'Pending',
          //   icon: <Bell size={20} />,
          //   link: '/pending',
          //   access: 'TELCOM_READ',
          // },


        ],
      },
      {
        name: 'Sales',
        icon: <Bell size={20} />,
        link: '/sales',
        access: 'TRACK_SALES',
      },

        {
        name: 'Bundles',
        icon: <Bell size={20} />,
        link: '/bundles',
        access: 'BUNDLES',
      },




      {
        name: 'Merchants',
        icon: <Code size={20} />,
        link: '/dealers',
        access: 'DEALER_READ',
        children: [
          {
            name: 'Dealer Information',
            icon: <CreditCard size={20} />,
            link: '/dealers',
            access: 'DEALER_READ',
          },

          {
            name: 'Agent Information',
            icon: <MapPin size={20} />,
            link: '/agents',
            access: 'AGENT_READ',
          },
          {
            name: 'Reseller/Corporate Information',
            icon: <MapPin size={20} />,
            link: '/reseller',
            access: 'AGENT_READ',
          },



        ],
      },




      {
        name: 'Messages',
        icon: <MessageCircle size={20} />,
        link: '/dbcdr',
        access: 'MESSAGE_READ',
        children: [
          {
            name: 'Dbcdr',
            link: '/dbcdr',
            access: 'MESSAGE_READ',
          },

          {
            name: 'Dbqueue',
            link: '/dbqueue',
            access: 'MESSAGE_READ',
          },
          {
            name: 'Sms Blast',
            link: '/smsblast',
            access: 'BLAST_READ',
          },
          {
            name: 'CommandTypes',
            link: '/command-types',
            access: 'COMMAND_TYPE_READ',
          },



        ],
      },
      // {
      //   name: 'IP Adresses',
      //   icon: <Code size={20} />,
      //   link: '/ip',
      //   access: 'IP_ADDRESS_READ',
      // },
      // {
      //   name: 'Portal Logs',
      //   icon: <Smartphone size={20} />,
      //   link: '/portal-logs',
      //   access: 'PORTAL_LOGS',
      // },
      // {
      //   name: 'Buy Airtime',
      //   icon: <CreditCard size={20} />,
      //   link: '/buy-airtime',
      //   access: 'BUY_AIRTIME',
      // },


      {
        name: 'Administration',
        icon: <UsersIcon size={20} />,
        link: '/admins',
        access: 'ADMIN_READ',
        children: [
          {
            name: 'Admins',
            link: '/admins',
            access: 'ADMIN_READ',
          },
          // {
          //   name: 'Maker Checker',
          //   link: '/maker-checker',
          //   access: 'MAKER_CHECKER_READ',
          // },
          {
            name: 'Roles',
            link: '/roles',
            access: 'ROLES_READ',
          },
        ],
      },
    ],
    currentRoute: window.location.pathname,
    active: 0,
  };
  render() {
    return (
      <>
        <div className='p-3 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow'>
          <MenuBars
            className='mr-3'
            onClick={() => {
              this.setState({ showMenu: true });
            }}></MenuBars>
          <div className='font-weight-bold top-bar-text'>
            {this.state.title}
          </div>
        </div>
        <div
          className={'menu-overlay ' + (this.state.showMenu ? 'show' : '')}
          onClick={() => {
            this.setState({ showMenu: false });
          }}></div>
        <div
          className={
            'sidebar-wrapper border-right shadow ' +
            (this.state.showMenu ? 'show' : '')
          }
          onClick={() => {
            this.setState({ showMenu: false });
          }}>
          <ul
            className='navbar-nav bg-light sidebar sidebar-dark accordion position-relative'
            id='accordionSidebar'>
            <div>
              <div className='d-flex flex-column user-login-card p-3 my-4 align-items-start'>
                <div className='d-flex flex-row align-items-start justify-content-between w-100'>
                  <div className='user-icon-parent'>
                    <img src={user} className='user-icon' alt='' />
                  </div>
                  <div className='d-flex flex-row'>
                    <div className='action-icon'>
                      {/* <Settings size={20}></Settings> */}
                    </div>

                    <div
                      className='action-icon'
                      onClick={() => window.logout()}>
                      <LogOut size={20}></LogOut>
                    </div>
                  </div>
                </div>
                <div className='mt-3 text-capitalize'>
                  {window.user.admin.name}
                </div>
                <div>
                  <small className='font-weight-bold'>{window.user.admin.group.name}</small>
                </div>
              </div>
            </div>

            {/* <div className='sidebar-heading text-dark mb-2 text-muted'>
              pages :
            </div> */}
            {this.state.menu.map((d, i) => {
              return (
                <li
                  className={
                    'nav-item ' +
                    (this.state.active === i && d.children
                      ? 'pb-4 no-hover '
                      : '') +
                    (this.state.currentRoute === d.link
                      ? 'active'
                      : 'text-dark')
                  }
                  key={i}>
                  <Access permission={d.access}>
                    {' '}
                    <Link
                      to={d.link}
                      onClick={(e) => {
                        if (this.state.active === i) {
                          this.setState({ active: 0 });
                        } else {
                          this.setState({ active: i });
                        }
                        if (d.children) e.preventDefault();
                      }}
                      className={
                        'nav-link ' +
                        (this.state.currentRoute === d.link
                          ? 'active'
                          : 'text-dark')
                      }>
                      <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>
                          {d.icon}
                          <div className='text-dark ml-2 font-weight-bold link-text'>
                            {d.name}
                          </div>
                        </div>
                        {d.children && (
                          <div>
                            {this.state.active !== i && (
                              <ChevronDown size={18}></ChevronDown>
                            )}
                            {this.state.active === i && (
                              <ChevronUp size={18}></ChevronUp>
                            )}
                          </div>
                        )}
                      </div>
                    </Link>
                  </Access>
                  {this.state.active === i && d.children && (
                    <div
                      id='collapseUtilities'
                      className='collapse show shadow-sm'
                      aria-labelledby='headingUtilities'
                      data-parent='#accordionSidebar'>
                      <div className=' py-2 collapse-inner rounded mb-0'>
                        {/* <h6 className='collapse-header'>listing:</h6> */}
                        {d.children.map((d1, i1) => {
                          if (d1.type === 'hr')
                            return (
                              <>
                                <hr className={`${!d1.title || 'mb-1'}`} />
                                {d1.title && (
                                  <div>
                                    <small className='text-muted text-uppercase font-weight-bold px-4'>
                                      {d1.title}
                                    </small>
                                  </div>
                                )}
                              </>
                            );

                          return (
                            <Access permission={d1.access} key={i1}>
                              <Link
                                className={
                                  'collapse-item font-weight-bold my-1 text-capitalize ' +
                                  (this.state.currentRoute === d1.link
                                    ? 'active'
                                    : 'text-dark')
                                }
                                to={d1.link}
                                key={i1}>
                                {d1.name}
                              </Link>
                            </Access>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
            {/*  */}
            {/* <li className='nav-item'>
            <button
              className='nav-link d-flex flex-row align-items-center bg-transparent btn'
              onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  localStorage.clear();
                  this.props.history.push("/login");
                }
              }}>
              <LogOut color='white' size={18} />
              <span className='text-white ml-2'>Logout</span>
            </button>
          </li> */}
          </ul>
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    if (props.history) {
      //   console.log(props.history);
      this.setState({ currentRoute: window.location.pathname });
    }
  }
}

export default withRouter(Menu);
