const regex = RegExp('foo*');


let telcoRegExp=[
  {
    telco:'SAFARICOMKE',
    regexp:RegExp(/^(\+){0,1}(254|0){0,1}(110|111|112|113|114|115|116|117|118|119|701|700|702|703|704|705|706|707|708|710|711|712|713|714|715|716|717|718|719|720|721|722|723|724|725|726|727|728|729|740|741|742|743|745|746|748|757|758|759|768|769|790|791|792|793|794|795|796|797|798|799)(\d{6})$/)
  },
  {
    telco:'AIRTELKE',
    regexp:RegExp(/^(\+){0,1}(254|0){0,1}(100|101|102|103|104|105|106|107|108|109|730|731|732|733|734|735|736|737|738|739|750|751|752|753|754|755|756|762|780|781|782|783|784|785|786|787|788|789|)(\d{6})$/)
  },
  {
    telco:'TELKOMKE',
    regexp:RegExp(/^(\+){0,1}(254|0){0,1}(77|)(\d{7})$/)
  },
  {
    telco:'FAIBAKE',
    regexp:RegExp(/^(\+){0,1}(254|0){0,1}(747|)(\d{6})$/)
  }
];
const parseStr = (...args) => {
const str = args[0];
const params = args.filter((arg, index) => index !== 0);
if (!str) return '';
return str.replace(/%s[0-9]+/g, matchedStr => {
const variableIndex = matchedStr.replace('%s', '') - 1;
return params[variableIndex];
});
};
function getTelco (msisdn) {

    for (let index = 0; index < telcoRegExp.length; index++) {
      const element = telcoRegExp[index];
      if(element.regexp.test(msisdn)){
        return element.telco;
      }
    }
  
    return Error('Invalid Phone Number');
    // return "SAFARICOM";
  }
  
  module.exports={
    getTelco,
    parseStr    
  };