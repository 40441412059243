import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link, Route } from 'react-router-dom';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import Tabs from '../../components/tabs';
import moment from 'moment';
import { CheckCircle, XCircle } from 'react-feather';

class Withdrawals extends Component {
  state = {
    tableData: { data: [] },
    tableData2: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    table_loading2: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='B2C Results'
         
          >

          </Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            
         

            <Table
              search={['msisdn']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchWithdrawals();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>

       
        </div>
      </div>
    );
  }

  fetchWithdrawals = () => {
    this.setState({ table_loading: true });

    let { query, filter } = this.state;
    let q = { ...query, ...filter };

    if (q.startdate) {
      q.tranTime = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    q.member_id = this.props.user;

    window.app
      .service('mpesa-disburse-complete')
      .find({
        query: {
          ...this.state.query,
          member_id: this.props.user,
        },
      })
      .then((response) => {
    	console.log(response);
        response.data = response.data.map((d) => {
	
          return {           
            msisdn:d.msisdn, 
            amount: 'Ksh. ' + d.amount.toLocaleString(),         
            transactionId: d.transaction_id,
            mpesatransactionId: d.mpesa_transaction_id,
            mpesaresponse: d.mpesa_resp_desc,
            date_created: moment(d.date_created).utcOffset('GMT+3').format('YYYY-MM-DD hh:mm:ss'),
          
	    
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  
  
}

export default Withdrawals;
