import React, { Component } from 'react';

class Access extends Component {
  permissions = window.roles;

  render() {
    // console.log(this.props.permission, this.permissions[this.props.permission]);
    let authorized =
      (this.permissions &&
        this.props.permission &&
        this.permissions[this.props.permission] &&
        this.permissions[this.props.permission].indexOf(
          window.user.admin.group.id
        ) !== -1) ||
      this.props.permission === 'all';

    return authorized ? <>{this.props.children}</> : <></>;
  }

  verify = (permission) => {
    this.permissions = window.roles;

    if (permission !== 'all') console.log(this.permissions);
    return (
      (this.permissions &&
        permission &&
        this.permissions[permission] &&
        this.permissions[permission].indexOf(window.user.admin.group.id) !==
          -1) ||
      permission === 'all'
    );
  };
}

export default Access;
