import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Modal from '../../components/modal';
import Form from '../../components/form';
import ReactJson from 'react-json-view';
import {
  ThumbsUp,
  StopCircle,
  RefreshCw,
  DollarSign,
  XCircle,
  Trash,
  LogOut,
  FileText,
  CheckCircle,
  Info,
} from 'react-feather';

class MakerCheckerLogs extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    data: {},
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav name='Approve / Reject'></Nav>

        {this.state.data.id && (
          <div className='table-card border-0 card shado mx-3 shadow'>
            <div className='card-header'>{this.state.data.transaction_id}</div>
            <div className='p-4'>
              <p>{this.state.data.description}</p>

              {!this.state.data.service_response_status && (
                <div className='d-flex flex-row mt-4'>
                  <button
                    className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                    onClick={() => {
                      this.setState({ approveModalVisible: true });
                    }}>
                    Approve
                  </button>

                  <button
                    className='btn btn-sm btn-round btn-outline-danger px-3'
                    onClick={() => {
                      this.setState({ rejectModalVisible: true });
                    }}>
                    Reject
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              //   search={[ 'first_name', 'other_name']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchMakerCheckerLogs();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
            />
          </div>
        </div>

        <Modal visible={this.state.approveModalVisible}>
          <h5 className='font-weight-bold'>Approve</h5>

          <hr />

          <div>
            <Form
              inputs={[
                {
                  label: 'Enter Description',
                  name: 'description',
                  type: 'textarea',
                },
              ]}
              submit={(data) => {
                if (
                  !window.confirm(
                    'Are you sure that you want to approve this Item?'
                  )
                )
                  return false;

                this.setState({ approveModalVisible: false });
                this.updateItem({
                  description: data.description,
                  action: 'APPROVE',
                  service_transaction_id: this.state.data.transaction_id,
                });
              }}
            />
          </div>
        </Modal>

        <Modal visible={this.state.rejectModalVisible}>
          <h5 className='font-weight-bold'>Approve</h5>

          <hr />

          <div>
            <Form
              inputs={[
                {
                  label: 'Enter Description',
                  name: 'description',
                  type: 'textarea',
                },
              ]}
              submit={(data) => {
                if (
                  !window.confirm(
                    'Are you sure that you want to approve this Item?'
                  )
                )
                  return false;
                this.setState({ rejectModalVisible: false });
                this.updateItem({
                  description: data.description,
                  action: 'APPROVE',
                  service_transaction_id: this.state.data.transaction_id,
                });
              }}
            />
          </div>
        </Modal>

        {this.state.json && (
          <Modal
            visible={true}
            close={() => {
              this.setState({ json: null });
            }}>
            <h5 className='font-weight-bold'>Data Preview</h5>
            <hr />
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              src={this.state.json}
            />
          </Modal>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.fetchMakerChecker();
  }

  fetchMakerCheckerLogs = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

    window.app
      .service('service-transaction-log')
      .find({
        query: {
          ...this.state.query,
          transaction_reference_id: this.props.match.params.id,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            admin: d.adminObj.name,

            level: d.level,
            response_status: d.response_status,
            response_txt: (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() =>
                  this.setState({ json: JSON.parse(d.response_txt) })
                }>
                view
              </button>
            ),
            serviceTransactionObj: d.serviceTransactionObj && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() =>
                  this.setState({ json: d.serviceTransactionObj })
                }>
                view
              </button>
            ),
            transaction_reference_id: d.transaction_reference_id,
            date_created: d.date_created,
            // date_processed: d.date_processed,
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  fetchMakerChecker = () => {
    this.setState({ table_loading: true });

    window.app
      .service('service-transactions')
      .find({ query: { id: this.props.match.params.id } })
      .then((response) => {
        this.setState({ data: response.data[0] });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  updateItem = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Updating Customer ...',
      buttons: false,
    });

    window.app
      .service('maker-checker')
      .create(data)
      .then((response) => {
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Updated Successfully',
          buttons: true,
          onSubmit: () => {
            this.props.history.push('/maker-checker/' );
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <Info size={60}></Info>,
          title: 'Info',
          message: err.message,
          buttons: true,
          onSubmit: () => {
            this.props.history.push('/maker-checker/' );
          },
        });
      });
  };
}

export default MakerCheckerLogs;
