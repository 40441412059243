import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';

class Loans extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav name='Portal Logs'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              // search={['firstname', 'lastname']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchLoans();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoans = () => {
    this.setState({ table_loading: true });

    window.app
      .service('portal-activity-log')
      .find({
        query: {
          ...this.state.query,
          // cust_no: this.props.user,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            Name: d.adminObj.name,
            activity_type: d.activity_type,           
            description: d.description,
            created_at: moment(d.adminObj.createdAt).format('YYYY-MM-DD hh:mm:ss'),
            // updated_at: d.updated_at
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Loans;
