import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Modal from "../../components/modal";
import Access from '../../components/accessManager';
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw
} from "react-feather";

class Admins extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    editModal: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Admins'
          buttons={[
            {
              text: 'Create an Admin',
              link: '/admin-create',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              // search={['firstname', 'lastname']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchAdmins();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
            <Modal
              visible={this.state.editModal}
              close={() => this.setState({ editModal: false })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.editRequest}>

                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Name</span>
                  <input className="form-control bg-light text-input" name="name" id="name" type="text" value={this.state.name} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Email</span>
                  <input className="form-control bg-light text-input" name="email" id="email" type="text" value={this.state.email} onChange={this.handleChange} style={{ width: '400px' }} />

                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Password</span>
                  <input className="form-control bg-light text-input" type="text" name="password" id="password" value={this.state.password} onChange={this.handleChange} style={{ width: '400px' }} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Group</span>
                  <input className="form-control bg-light text-input" name="group" id="group" type="text" value={this.state.group} onChange={this.handleChange} style={{ width: '400px' }} />
                </div>
               


                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit</button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  fetchAdmins = () => {
    this.setState({ table_loading: true });

    window.app
      .service('admins')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            name: d.name,
            email: d.email,
            group: d.group.name,
            secondFactor_selected: d.secondFactor_selected,
            secondFactor_status: d.secondFactor_status,
            createdAt:
              d.createdAt && moment(d.createdAt).format('YYYY-MM-DD hh:mm:ss'),
            // action: (
            //   <Access permission='ADMIN_CREATE'>
            //     <Link className='btn btn-primary' to={`/admins/edit/${d.id}`}>
            //       Edit
            //     </Link>
            //   </Access>
            // ),
            action: (
              <div className="d-flex flex-row">
                <button
                  onClick={() => {
                    this.setState({
                      editModal: true, activeDetails: d, name: d.name, email: d.email, id: d.id, group: d.group.name,
                      secondFactor_selected: d.secondFactor_selected, secondFactor_status: d.secondFactor_status
                    });

                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  Edit
                </button>

              </div>
            )
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  editRequest = (event) => {
    event.preventDefault();
    let id = this.state.id;
    let data = {
      id: this.state.id,
      email: this.state.email,
      password: this.state.password,
      group: this.state.group
    };
    if (
      !window.confirm("Are you sure that you want to Eit this user?")
    )
      return false;

    // console.log(data)

    window.app
      .service('admins')
      .patch(id, data)
      .then((response) => {
        this.setState(response);

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Admin updated Successfully',
          buttons: true,
        });
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
        console.error(err);
      });

  };
  handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }
}

export default Admins;
