import React, { Component ,useEffect, useState} from 'react';
import moment from 'moment';

class Summary extends Component {
  state = { paymentRequest: {} ,wallets:[]};
  
  render() {
    return (
      <div>
        <div className='row p-3'>
          <div className='col-md-6'>
            <div className='card'>
              <table className='table table-striped table-hover'>
                {Object.keys(this.state.paymentRequest).map((d, i) => {
                  return (
                    <tr key={i}>
                      <td className='text-capitalize'>
                        {d.replace(/\_/g, ' ')}
                      </td>
                      <td>{this.state.paymentRequest[d] ? this.state.paymentRequest[d] : '-'}</td>
                    </tr>
                  );
                })}

              </table>
            </div>
          </div>

          {/*<div className='col-md-6'>*/}
          {/*  <div className='card'>*/}
          {/*    <table className='table table-striped table-hover'>*/}
          {/*    <tr>*/}
          {/*    <td>Wallet Account</td>*/}
          {/*    <td>Balance</td>*/}
          {/*      </tr>*/}

          {/*      {this.state.wallets?.data?.map(d => {*/}
          {/*        return (*/}
          {/*          <tr key={d.id}>*/}
          {/*            <td className='text-capitalize'>*/}
          {/*              <b>{d.type}</b>*/}
          {/*            </td>*/}
          {/*            <td><code>KES {d.amount}</code></td>*/}
          {/*          </tr>*/}
          {/*        );*/}
          {/*      })}*/}

          {/*    </table>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }

  componentDidMount() {
    console.log(this.props.paymentRequest);
    let paymentRequest = this.props.paymentRequest;
    this.setState({ paymentRequest });


  }

  
}

export default Summary;
