import React, { Component } from "react";
import Modal from "../../components/modal";
import { CheckCircle } from "react-feather";
import ReactFileReader from 'react-file-reader';

class DepositsRecon extends Component {
    state = {};
    render() {
        return (
            <div className="p-3 container">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-column ">
                        <h2 className="m-0 mr">Reconcile Deposits</h2>{" "}
                    </div>
                </div>
                {/* <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
    <button className='btn'>Upload</button>
</ReactFileReader> */}

                <div className="">
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div class="form-group">
                                <label for="fileInput1">Upload failed deposits file</label>
                                <input
                                    type="file"
                                    class="form-control"
                                    id="fileInput1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter file"
                                    onChange={e => this.setState({ file: e.target.files[0] })}
                                />
                                <button
                                    className="btn btn-primary mt-5 px-5"
                                    disabled={!this.state.file}
                                    onClick={this.importCSV}>
                                    Upload
                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.modalVisible}
                    dismiss={false}
                    close={() => this.setState({ modalVisible: false })}>
                    <div>
                        <div className="d-flex flex-row align-items-center">
                            {this.state.uploadSuccessful ? (
                                <CheckCircle color="#4caf50" size={64} />
                            ) : (
                                    <div className="lds-spinner">
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                )}
                            <div className="ml-5 text-dark">
                                {!this.state.uploadSuccessful ? (
                                    <h3>Uploading ...</h3>
                                ) : (
                                        <h3>Upload Successful. System will take care of the rest</h3>
                                    )}
                            </div>
                        </div>
                        {this.state.uploadSuccessful && (
                            <div className="text-center">
                                <button
                                    className="btn btn-primary mt-4 px-5"
                                    onClick={() => this.setState({ modalVisible: false })}>
                                    Done
                </button>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
    importCSV = (event) => {
        event.preventDefault();
        var data = new FormData();
        data.append("file", this.state.file);
        var Papa = require("papaparse/papaparse.min.js");
        Papa.parse(this.state.file, {
            header: true,
            download: true,
            skipEmptyLines: true,
            complete: this.updateData
        });
    }

    //updateData = () => {
        updateData = async (result) => {
        let newJson = [];
        result.data.map(async (value) => {
            console.log(value)
            newJson.push({ transactionId: value.transactionId, msisdn: value.msisdn })
        })
         console.log(newJson);
        // window.app
        //   .service('mpesa-pull-results')
        //   .create(newJson)
        //   .then(() => {
        //     // alert('added');
        //     this.setState({addModal: false });
        //     this.fetchRequests();
        //     // window.location.reload();
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     this.setState({ modalVisible: false });
        //    
        //   });
    };

}

export default DepositsRecon;