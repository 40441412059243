import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
//import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';
var moment = require('moment-timezone');


class RequestsLogsArchive extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Successful Requests'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
          <Filter
            
            dateRange={['startdate', 'enddate']}
            dateRangeLabel='Date Created'
            getFilter={(filter) => {
              this.setState({ filter });
              setTimeout(() => {
                this.fetchRequestLogs();
              }, 0);
            }}
          />
       

            <Table
              search={['account_no','payment_ref']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchRequestLogs();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'Requests',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>               
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}


          
        </div>
      </div>
    );
  }

  fetchRequestLogs = () => {
    this.setState({ table_loading: true });
 

    let { query, filter } = this.state;
    let q = { ...query, ...filter };
   

    if (q.startdate) {
      q.date_created = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    window.app
      .service('request-log-archive')
      .find({
        query: {
          ...q,
          msisdn:this.props.user,
        },
      })
      .then((response) => {
        
        response.data = response.data.map((d) => {
          return {
           
            "Mpesa Number": d.msisdn,
            "name": d.name,
            "Airtime Number": d.account_no, 
            "payment_ref": d.payment_ref,  
            // "response": d.response,
            response: d.response && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() => 
                this.setState({ json: d })
              }>
                view
              </button>
            ),          
            "amount": "KES "+parseFloat(d.amount).toLocaleString(),            
            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
            
            
            
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default RequestsLogsArchive;
