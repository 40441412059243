import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class RequestsQueue extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Requests Queue'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
       

            <Table
              search={['msisdn']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchRequestQueue();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'Requests Queue',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchRequestQueue = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

    let status = {
      0: <span className='badge badge-danger'>Inactive</span>,
      2: <span className='badge badge-warning'>Account locked</span>,
      3: <span className='badge badge-success'>Active</span>,
    };

    window.app
      .service('request-queue')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
           
            "msisdn": d.msisdn,
            "name": d.name,          
            "account_no": d.account_no, 
            "payment_ref": d.payment_ref,  
            "narration": d.narration,         
            "amount": "KES "+parseFloat(d.amount).toLocaleString(),                          
            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
            
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default RequestsQueue;
