import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';

class RequestsLogsArchive extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Successful Requests'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'> 

        
         
         <Filter        


           getFilter={filter => {
             setTimeout(() => {
               this.setState({
                 filter
               });                
             }, 0);
           }}
           
           dateRange={["startdate", "enddate"]}
           bonusType={["bonus_type"]}
         />        
          <div className='p-4'>
            <Table
              search={['msisdn','payment_ref','account_no']}
              sort="tran_date"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                // setTimeout(() => {
                //   this.fetchRequestLogs();
                // }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'Requests',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }

  fetchRequestLogs = () => {
    // let { query } = this.state;
    // let { filter } = this.state;
     let startdate = moment(this.state.filter.startdate).format("YYYY-MM-DD");
     let enddate = moment(this.state.filter.enddate).format("YYYY-MM-DD");

  

    let { query, filter } = this.state;
    let q = { ...query, ...filter };
    console.log('q is',q)

    if (q.startdate) {
      q.tran_date = {
        $gte:q.startdate+" 00:00:00",
        $lte: q.enddate+" 23:59:00",
      };
      delete q.startdate;
      delete q.enddate;
    }
    else{
      q.tran_date = {
        $gte:startdate+" 00:00:00",
        $lte: enddate+" 23:59:00",
      };
      delete q.startdate;
      delete q.enddate;

    }

     //let get_msisdn = query.$or[0].msisdn.$like.split("%")[1];
    // let array_filter = [];

    // if(get_msisdn === ""){
    //   array_filter.push(
    //     { tran_date: {$gte: startdate+ " 00:00:00"} },
    //     { tran_date: { $lte: enddate + " 23:59:00" } }
    //   )
    // }else{
    //   array_filter.push({
        
    //   })
    // }

    this.setState({ table_loading: true });

    

    window.app
      .service('request-log-archive')
      .find({
        query: {
          ...q
         // ...this.state.query,
         // $and: array_filter
        //  $and: [ { tran_date: {$gte: startdate+ " 00:00:00"} },
        //  { tran_date: { $lte: enddate + " 23:59:00" } }
        // ]
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          var sum_total=d.amount+d.bonus;
          return {
            "ClientId":d.user_id,           
            "MpesaNumber": d.msisdn,
            "name": d.name,          
            "AirtimeNumber": d.account_no, 
             "payment_ref": d.payment_ref,  
            "response": d.response, 
            response: d.response && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() => 
                this.setState({ json: d })
              }>
                view
              </button>
            ),         
            "amount": "KES "+parseFloat(d.amount).toLocaleString(),
            "bonus": "KES "+parseFloat(d.bonus).toLocaleString(),
            "total": "KES "+parseFloat(sum_total).toLocaleString(),
            "BonusType": d.bonus_type,
            "provider": d.provider,                          
            "date_created":moment.utc(d.tran_date).format("DD MMMM, YYYY.  HH:mm:ss"),
            "date_modified":moment(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
                               
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
        JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
        JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
        let $t = this;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            $t.fetchRequestLogs();
        }, 100);
    }
}
}

export default RequestsLogsArchive;
