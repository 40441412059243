import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
// import Details from "./clients/details";
import {
  ThumbsUp,
  StopCircle,
  RefreshCw,
  DollarSign,
  XCircle,
  Trash,
  LogOut,
  FileText,
  CheckCircle,
  Info,
} from 'react-feather';
import Tabs from '../../components/tabs';
import UserSummarry from './summarry';
import Transactions from '../finance/transactions';
import Withdrawals from '../finance/userwithdrawals';
import Deposits from '../finance/userdeposits';
import Form from '../../components/form';
import Modal from '../../components/modal';
import Customers from '../dealers/dealerscustomers';
import Agents from '../dealers/dealersagents';
import Customerslogs from '../dealers/customersLogs';
import Requestlogarchive from '../requests/userrequestsLogArchive';
import Requestlogfailed from '../requests/userrequestsLogFailed';
import Dbqueue from '../messages/userdqueue';
import Access from '../../components/accessManager';

class CustomerDetails extends Component {
  state = { modalVisible: false };
  render() {
    let user = [];

    return (
      <div className='bg-light'>
        <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
          <div className='text-mute pt-3 pl-3'>
            <small className='text-mute'>Dealers &gt; View</small>
          </div>

          <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
            <div className='d-md-flex flex-row align-items-center'>
              <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <span className='initials'>
                  {this.state.cust_name && this.state.cust_name[0]}
                </span>
                {/* <img
                  src={`${window.fileserver}${this.state.avatar}`}
                  className='avatar'
                  alt=''
                /> */}
              </div>
              <div className='ml-md-4 my-3 my-md-0'>
                <h4 className='text-capitalize'>
                  {this.state.cust_name} {this.state.cust_name}
                </h4>
                <div>{this.state.msisdn}</div>
                <div className='ml-2 mt-1'>
                  <span className='badge badge-secondary px-2'>Customer</span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-row flex-wrap member-view '>
              <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
              
              </div>
            </div>
          </div>
        </div>
        <div className='position-relative px-3'>
          {this.state.msisdn && (
            <Tabs>
              <Route
                permission='all'
                path={`/dealer/${this.props.match.params.id}/details`}
                title='Details'>
                <UserSummarry data={this.state}></UserSummarry>
              </Route>
              <Route
                permission='DEALER_READ'
                path={`/dealer/${this.props.match.params.id}/agents`}
                title='Agents'>
                <Agents user={this.state.id}></Agents>
              </Route> 
              <Route
                permission='DEALER_READ'
                path={`/dealer/${this.props.match.params.id}/customers`}
                title='Customers'>
                <Customers user={this.state.id}></Customers>
              </Route> 
              <Route
                permission='DEALER_READ'
                path={`/dealer/${this.props.match.params.id}/customerslogs`}
                title='Commission Statement'>
                <Customerslogs user={this.state.id}></Customerslogs>
              </Route>          
             
         
            </Tabs>
          )}
        </div>

      </div>
    );
  }

  componentDidMount = () => {
    this.fetchCustomer();
  };

  fetchCustomer = () => {
    window.app
      .service('customer')
      .get(this.props.match.params.id)
      .then((response) => {
        this.setState(response);
      });
  };

  updateCustomer = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Updating Customer ...',
      buttons: false,
    });

    window.app
      .service('customers')
      .patch(this.state.id, data)
      .then((response) => {
        window.alert2.show({
          loader: false,
          msisdn: this.state.msisdn,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Updated Successfully',
          buttons: true,
          onSubmit: () => {
            this.props.history.push('/customers/' + response.id + '/details');
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.code !== 500) {
          window.alert2.show({
            loader: false,
            icon: <Info size={60}></Info>,
            title: 'MakerChecker Alerts',
            message: err.message,
            buttons: true,
            onSubmit: () => {
              window.location.reload();
            },
          });

          return;
        }

        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };

  updateWithdrawals = () => {
    window.alert2.show({
      loader: true,
      title: 'Reconciling ...',
      buttons: false,
    });

    window.app
      .service('recon')
      .create({})
      .then((response) => {
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Reconciled Successfully',
          buttons: true,
          onSubmit: () => {
            window.location.reload();
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };
}

export default CustomerDetails;
