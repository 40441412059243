import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
// import Details from "./clients/details";
import {
    ThumbsUp,
    StopCircle,
    RefreshCw,
    DollarSign,
    XCircle,
    Trash,
    LogOut,
    FileText,
    CheckCircle,
    Info,
    Edit,
} from 'react-feather';
import Tabs from '../../components/tabs';
import UserSummarry from './summarry';
import Transactions from '../finance/transactions';
import Withdrawals from '../finance/userwithdrawals';
import Deposits from '../finance/userdeposits';
import Form from '../../components/form';
import Modal from '../../components/modal';
import Sales from '../reseller/sales';
import Topups from '../reseller/topups';
import Successful from '../reseller/successful';
import Customerslogs from '../dealers/customersLogs';
import Requestlogarchive from '../requests/userrequestsLogArchive';
import Requestlogfailed from '../requests/userrequestsLogFailed';
import Dbqueue from '../messages/userdqueue';
import Access from '../../components/accessManager';
import {v4 as uuidv4} from 'uuid';


class CustomerDetails extends Component {
    state = { modalVisible: false,
        addModal:false, addModalcommission:false,
        amount:0,payment_reference:uuidv4()};
    
    render() {
        let user = [];

        return (
            <div className='bg-light'>
                <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
                    <div className='text-mute pt-3 pl-3'>
                        <small className='text-mute'>Service Clients &gt; View</small>
                    </div>

                    <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
                        <div className='d-md-flex flex-row align-items-center'>
                            <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                                <span className='initials'>
                                    {this.state.ClientId && this.state.ClientId[0]}
                                </span>
                                {/* <img
                                    src={`${window.fileserver}${this.state.avatar}`}
                                    className='avatar'
                                    alt=''
                                /> */}
                            </div>
                            <div className='ml-md-4 my-3 my-md-0'>
                                <h4 className='text-capitalize'>
                                    {this.state.ClientId}
                                </h4>
                                <div>{this.state.msisdn}</div>
                                <div className='ml-2 mt-1'>
                                    <span className='badge badge-secondary px-2'>Customer</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-row flex-wrap ">
                            <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
                            <button 
                                 onClick={this.handleClick}
                                className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                                    <Edit size={18} />
                                    <span className="pl-1 font-weight-bold">Top Up</span>
                                </button>                                 
                            </div>

                            <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
                            <button 
                                 onClick={this.handleClickcommission}
                                className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                                    <Edit size={18} />
                                    <span className="pl-1 font-weight-bold">Update Commission Percentage</span>
                                </button>                                 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative px-3'>
                    {this.state.ClientId && (
                        <Tabs>
                            <Route
                                permission='all'
                                path={`/reseller/${this.props.match.params.client}/${this.props.match.params.id}/${this.props.match.params.units}/details`}
                                title='Details'>
                                <UserSummarry data={this.state}></UserSummarry>
                            </Route>
                            <Route
                                permission='DEALER_READ'
                                path={`/reseller/${this.props.match.params.client}/${this.props.match.params.id}/${this.props.match.params.units}/topups`}
                                title='TopUps/AccountLog'>
                                <Topups user={this.state.id}></Topups>
                            </Route>
                            <Route
                                permission='DEALER_READ'
                                path={`/reseller/${this.props.match.params.client}/${this.props.match.params.id}/${this.props.match.params.units}/transactions`}
                                title='Successful Transactions'>
                                <Successful user={this.props.match.params.client}></Successful>
                            </Route>  
                            <Route
                                permission='DEALER_READ'
                                path={`/reseller/${this.props.match.params.client}/${this.props.match.params.id}/${this.props.match.params.units}/sales`}
                                title='Daily Total Sales'>
                                <Sales user={this.props.match.params.client}></Sales>
                            </Route>  
                                         
                         
                 
                        </Tabs>
                    )}
                </div>
                <Modal
                            visible={this.state.addModal}
                            close={() => this.setState({ addModal: false, errors: {} })}>
                            <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

                            <form onSubmit={this.toupRequest}>
                                <div className="mx-3 d-inline-block mb-3">
                                <span className="ml-1 mb-2 d-block text-capitalize">ServiceClient</span>
                                <input className="form-control bg-light text-input" name="id" id="id" type="text" value={this.state.id} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">PaymentReference</span>
                                    <input className="form-control bg-light text-input" name="payment_reference" id="payment_reference" type="text" value={this.state.payment_reference} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                             
                                </div>
                                    <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Amount:</span>
                                    <input className="form-control bg-light text-input" name="amount" id="amount" type="text" value={this.state.amount} onChange={this.handleChange} style={{ width: '400px' }} />
                             
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Remarks:</span>
                                    <input className="form-control bg-light text-input" name="remarks" id="remarks" type="text" value={this.state.remarks} onChange={this.handleChange} style={{ width: '400px' }} />							 
                                </div>						 
                            
                                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Top Up</button>
                                </div>
                            </form>
                    </Modal>


                     <Modal
                            visible={this.state.addModalcommission}
                            close={() => this.setState({ addModalcommission: false, errors: {} })}>
                            <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

                            <form onSubmit={this.updatepercentage}>
                                <div className="mx-3 d-inline-block mb-3">
                                <span className="ml-1 mb-2 d-block text-capitalize">ServiceClient</span>
                                <input className="form-control bg-light text-input" name="id" id="id" type="text" value={this.state.id} readOnly="readOnly" onChange={this.handleChange} style={{ width: '400px' }} />
                                </div>
                               
                               
                                    <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Percentage:</span>
                                    <input className="form-control bg-light text-input" name="percentage_commission" id="percentage_commission" type="text" value={this.state.percentage_commission} onChange={this.handleChange} style={{ width: '400px' }} />
                             
                                </div>
                                                      
                            
                                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update Percentage</button>
                                </div>
                            </form>
                    </Modal>

            </div>
        );
    }

    componentDidMount = () => {
        this.fetchCustomer();
    };

    fetchCustomer = () => {
        window.app
            .service('service-clients')
            .get(this.props.match.params.id)
            .then((response) => {
                console.log('this is response',response);
                this.setState(response);
            });
    };

    updateCustomer = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating Customer ...',
            buttons: false,
        });

        window.app
            .service('customers')
            .patch(this.state.id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    msisdn: this.state.msisdn,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/customers/' + response.id + '/details');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                console.log(err);
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}></Info>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

    updateWithdrawals = () => {
        window.alert2.show({
            loader: true,
            title: 'Reconciling ...',
            buttons: false,
        });

        window.app
            .service('recon')
            .create({})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Reconciled Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };
    handleClick = () => {
        this.setState({ addModal: true });

    };
    handleClickcommission = () => {
        this.setState({ addModalcommission: true });

    };
    toupRequest = (event) => {
        event.preventDefault();
            // let id = this.props.match.params.id;
            let data = {
                service_client_id: this.state.id,
                payment_reference:this.state.payment_reference,
                amount:this.state.amount,
                remarks:this.state.remarks
             
                
            };
            console.log('data is',data)
            if (
                !window.confirm("Are you sure that you want to top up this account?")
            )
                return false;

    

            window.app
                .service('service-client-topup')
                .create(data)
                .then((response) => {
                    this.setState(response);

                    window.alert2.show({
                        loader: false,
                        icon: <CheckCircle size={60}></CheckCircle>,
                        title: 'Account topped up Successfully',
                        buttons: true,
                    });
                 //this.refreshTable('default');
                 window.location.reload();
                })
                .catch((err) => {
                    window.alert2.show({
                        loader: false,
                        icon: <XCircle size={60} className='text-danger'></XCircle>,
                        title: 'Error',
                        message: err.message,
                        buttons: true,
                    });
                    console.error(err);
                });
        
    };


    updatepercentage = (event) => {
        event.preventDefault();
        var id =this.state.id
          
            let data = {                              
                percentage_commission:this.state.percentage_commission
                
                
            };
           
            if (
                !window.confirm("Are you sure that you want to update percentage Commission?")
            )
                return false;

    

            window.app
                .service('service-clients')
                .patch(id, data)
                .then((response) => {
                    this.setState(response);

                    window.alert2.show({
                        loader: false,
                        icon: <CheckCircle size={60}></CheckCircle>,
                        title: 'Commission Updated Successfully',
                        buttons: true,
                    });
                 //this.refreshTable('default');
                 window.location.reload();
                })
                .catch((err) => {
                    window.alert2.show({
                        loader: false,
                        icon: <XCircle size={60} className='text-danger'></XCircle>,
                        title: 'Error',
                        message: err.message,
                        buttons: true,
                    });
                    console.error(err);
                });
        
    };
    handleChange = (e) => {
        const { id, value } = e.target
        console.log(e.target.id);
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
}

export default CustomerDetails;
