import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { ChevronDown, User, X } from 'react-feather';
import {
  ThumbsUp,
  StopCircle,
  RefreshCw,
  DollarSign,
  XCircle,
  Trash,
  LogOut,
  FileText,
  CheckCircle,
  Info,
} from 'react-feather';
import Tabs from '../../components/tabs';
import Access from '../../components/accessManager';

import {default as Summary} from './Summary';
import {default as PaymentRequestLogs} from './payment_request_logs';

import {default as MpesaSTKDetails} from './../mpesa/stk';
import {default as MpesaC2BResults} from './../mpesa/c2b_results';


class ResultDetails extends Component {
  state = { modalVisible: false };
  render() {
    let user = [];

    return (
      <div className='bg-light'>
        <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
          <div className='text-mute pt-3 pl-3'>
            <small className='text-mute'>PaymentRequest &gt; View</small>
          </div>

          <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
            <div className='d-md-flex flex-row align-items-center'>
              <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                <User></User>
              </div>
              </div>
              <div className='ml-md-4 my-3 my-md-0'>
                <h4 className='text-capitalize'>
                  PaymentRequestId: {this.state.id}
                </h4>
                <div>Msisdn: <code>{this.state.msisdn}</code></div>
                <div>ExtRef: <code>{this.state.reference}</code></div>
                <div className='ml-2 mt-1'>
                  <span className='badge badge-secondary px-2'>Request</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='position-relative px-3'>
          {this.state.id && (
            <Tabs>
              <Route
                permission='all'
                path={`/payment_requests/${this.props.match.params.id}/details`}
                title='Details'>
                <Summary paymentRequest={this.state}/>
              </Route>
              <Route
                  permission='all'
                  path={`/payment_requests/${this.props.match.params.id}/logs`}
                  title='Logs'>
                <PaymentRequestLogs paymentRequest={this.state}/>
              </Route>

              <Route
                  permission='all'
                  path={`/payment_requests/${this.props.match.params.id}/mpesa_stk`}
                  title='MpesaSTK'>
                <MpesaSTKDetails paymentRequest={this.state}/>
              </Route>

              <Route
                  permission='all'
                  path={`/payment_requests/${this.props.match.params.id}/mpesa_c2b_results`}
                  title='MpesaC2BResults'>
                <MpesaC2BResults paymentRequest={this.state}/>
              </Route>



            </Tabs>
          )}
        </div>

      </div>
    );
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    console.log(this.props.match.params)
    window.app
        .service('payments/requests')
        .find({
          query:{
            $or: [
              { id: this.props.match.params.id },
              { ack_payment_reference: this.props.match.params.id }
            ]          }
        })
        // .get(this.props.match.params.id)
      .then((response) => {
        console.log(response)
        response=response.data[0]
        this.setState(response);
      });
  };

  updateRecord = (data) => {
    window.alert2.show({
      loader: true,
      title: 'Updating PaymentRequest ...',
      buttons: false,
    });

    window.app
      .service('payments/requests')
      .patch(this.state.id, data)
      .then((response) => {
        window.alert2.show({
          loader: false,
          msisdn: this.state.msisdn,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Updated Successfully',
          buttons: true,
          onSubmit: () => {
            this.props.history.push('/customers/' + response.id + '/details');
          },
        });
        this.setState(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.code !== 500) {
          window.alert2.show({
            loader: false,
            icon: <Info size={60}></Info>,
            title: 'MakerChecker Alerts',
            message: err.message,
            buttons: true,
            onSubmit: () => {
              window.location.reload();
            },
          });

          return;
        }

        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
      });
  };


}

export default ResultDetails;
