import React, { Component } from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';

// {
//     addOnData: "{"msisdn":"254726354124"}"
// createdAt: null
// email: "dkanake@test.com"
// group: {id: 1, name: "Admins"}
// group_id: 1
// id: 4
// last_ip_login: null
// last_login_date: null
// name: "Dominic Kanake"
// password: "$2a$10$rRi.veiYZhhz7YyPN6ygxOEnAgQC4Q2Pi4q.aj9VYl4i9omXE/TH6"
// secondFactor_selected: "SMS"
// secondFactor_status: 0
// status: 1
// updatedAt: null
// verifyExpiry: null
// }

class AdminEdit extends Component {
  state = { inputs: {} };
  render() {
    return (
      <div className=''>
        <Nav name='Edit Admin'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
          <div className='order-form'>
            <Form
              inputs={[
                {
                  label: 'name',
                  name: 'name',
                  type: 'text',
                  value: this.state.inputs.name,
                },
                {
                  label: 'Phone Number',
                  name: 'msisdn',
                  type: 'tel',
                  value: this.state.inputs.msisdn,
                },
                {
                  label: 'email',
                  name: 'email',
                  type: 'email',
                  value: this.state.inputs.email,
                },
                {
                  label: 'password',
                  name: 'password',
                  type: 'password',
                  value: this.state.inputs.password,
                },
                {
                  label: 'secondFactor selected',
                  name: 'secondFactor_selected',
                  type: 'select',
                  options: [
                    {
                      name: 'SMS',
                      value: 'SMS',
                    },
                  ],
                  value: this.state.inputs.secondFactor_selected,
                },

                {
                  label: 'secondFactor Status',
                  name: 'secondFactor_status',
                  type: 'select',
                  options: [
                    {
                      name: 'Enabled',
                      value: 0,
                    },
                    {
                      name: 'Disabled',
                      value: 1,
                    },
                  ],
                  value: this.state.inputs.secondFactor_status,
                },

                {
                  label: 'status',
                  name: 'status',
                  type: 'select',
                  options: [
                    {
                      name: 'Active',
                      value: 1,
                    },
                    {
                      name: 'Inactive',
                      value: 0,
                    },
                  ],
                  value: this.state.inputs.status,
                },

                {
                  label: 'group',
                  name: 'group_id',
                  type: 'select',
                  options: window.groups.map((d) => {
                    return {
                      name: d.name,
                      value: d.id,
                    };
                  }),
                  value: this.state.inputs.group_id,
                },
              ]}
              submit={(data) => {
                this.updateAdmin(data);
              }}></Form>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetchAdmin();
  };

  updateAdmin = (data) => {
    window.alert2.show({
      loader: true,
      title: 'updating Admin ...',
      buttons: false,
    });

    window.app
      .service('admins')
      .patch(data)
      .then((response) => {
        this.setState(response);

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Admin updated Successfully',
          buttons: true,
        });
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
        console.error(err);
      });
  };

  fetchAdmin = () => {
    this.setState({ table_loading: true });
    // window.alert2.show({
    //   loader: true,
    //   title: 'Loading ...',
    //   buttons: false,
    // });
    window.app
      .service('admins')
      .get(this.props.match.params.id)
      .then((inputs) => {
        this.setState({ inputs });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default AdminEdit;
