import React, { Component } from 'react';
import moment from 'moment';
import { Check, X } from 'react-feather';
import Access from './accessManager';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DropdownMultiple, Dropdown } from 'reactjs-dropdown-component';

class Filter extends Component {
  state = {
    ranges: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    },
    query: {},
    dateVisible: false,   
    bonustype: [
      {
        label: 'All',
        value: "0",
      },
      {
        label: 'new_customer_bonus',
        value: 'new_customer_bonus',
      },
      {
        label: 'lastActivity_30_day',
        value: 'lastActivity_30_day',
      }
     

    ],
    type:'all'
  };

  render() {
    return (
      <>
        <div className='d-flex flex-row justify-content-between mb-3 position-relative'>
          <div className='d-flex flex-row'>
            {this.props.filter &&
              this.props.filter.map((d, i) => (
                <div className='mx-2 flex-column' key={i}>
                  <small className='mb-2 font-weight-bold'>{d.label}</small>
                  <select
                    name=''
                    id=''
                    className='form-control py-1 filter-option'
                    onChange={(e) => {
                      let { query } = this.state;

                      let option = d.options[e.target.value];

                      if (option.default) {
                        console.log(option.default);
                        delete query[d.name];
                      } else {
                        query[d.name] = option.value;
                      }

                      this.setState({ query });
                      setTimeout(() => {
                        this.updateFilter();
                      }, 0);
                    }}
                  >
                    {d.options.map((d1, i1) => (
                      <option value={i1} key={i1}>
                        {d1.label}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
          </div>

          {this.props.dateRange && (
            <div className='mx-2 flex-column'>
              <small className='mb-2 font-weight-bold'>Date range </small>
              <div
                className='form-control filter-option py-0 d-flex flex-row align-items-center noselect'
                onClick={() => {
                  this.setState({ dateVisible: true });
                }}>
                <span>
                  <b>From</b>
                  {moment(this.state.ranges.startDate).format(' MMMM Do YYYY ')}
                  <b>&nbsp;&nbsp; To</b>
                  {moment(this.state.ranges.endDate).format(' MMMM Do YYYY ')}
                </span>
              </div>
            </div>
          )}

          <div
            className={
              'card date-card ' + (this.state.dateVisible ? '' : 'd-none')
            }>
            <DateRangePicker
              ranges={[this.state.ranges]}
              onChange={ranges => this.updateDateRange(ranges)}
            />

            <div className='p-3 text-center d-flex flex-row justify-content-between'>
              <button
                onClick={() => {
                  this.setState({ dateVisible: false });
                }}
                className='btn btn-outline-danger btn-sm d-flex flex-row
                align-items-center align-self-center pr-4'>
                <X size={18} /> <span className='ml-2'>Cancel</span>
              </button>

              <button
                onClick={() => {
                  this.setState({ dateVisible: false });
                }}
                className='btn btn-primary btn-sm d-flex flex-row align-items-center align-self-center pr-4'>
                <Check size={18} /> <span className='ml-2'>Done</span>
              </button>
            </div>            
          </div>
        
        <div
          onClick={() => {
            this.setState({ dateVisible: false });
          }}
          className={
            'date-bg-cover cursor-pointer ' +
            (this.state.dateVisible ? '' : 'd-none')
          }
        />
        </div>
        {this.props.bonusType && (
          <Dropdown  
          name="bonustype"
          title="Select BonusType"
          list={this.state.bonustype}
          type={[this.state.type]}
          onChange={type => this.onChange(type)}
          // onChange={this.onChange}
          styles={{
            headerTitle: { fontSize: '15px' },
            wrapper:{width:'300px',marginLeft:'10px'},
            list:{ textAlign: 'right',fontWeight: 'normal',overflowy: 'hidden'}
          }}
          
        />
          )}
      </>
    );
  }

  componentDidMount() {
    // this.updateFilter();
  }
  onChange(item, name,type){
    this.setState({ type: item.value});
 
   
  }


  updateDateRange(ranges) {
    // //console.log(ranges);
    if (ranges) this.setState({ ranges: ranges.selection });
  }
  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.updateFilter();
    }
  }

  updateFilter = () => {
    let filterData = {};
   

      filterData[this.props.dateRange[0]] = moment(
        this.state.ranges.startDate
      ).format('YYYY-MM-DD');

      filterData[this.props.dateRange[1]] = moment(
        this.state.ranges.endDate
      ).format('YYYY-MM-DD');

      filterData[this.props.bonusType] = this.state.type;
   if(this.state.type != "0"){
    filterData = { ...filterData, ...this.state.filter };
   }else{
    delete filterData['bonus_type'];
     //alert(JSON.stringify(filterData))
     
    filterData = { ...filterData, ...this.state.filter }
   }
  
    //console.log(filterData);
    this.props.getFilter(filterData);
  };
}

export default Filter;