import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw
} from "react-feather";

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    updateModal:false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Pull Requests'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            

            <Table
              search={['shortcode']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
            <Modal
              visible={this.state.updateModal}
              close={() => this.setState({ updateModal: false})}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.updateRequest}>              
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Initiator</span>
                  <input name="reference_id" id="initiator_id" type="text" value={this.state.initiator_id} readOnly="readOnly" style={{ width: '400px' }} />

                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">reference_id</span>
                  <input type="text" name="reference_id" id="reference_id" value={this.state.reference_id} readOnly="readOnly" style={{ width: '400px' }} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                  <input className="form-control bg-light text-input" name="account_no" id="account_no" type="text" value={this.state.account_no} onChange={this.handleChange} style={{ width: '400px' }} />
               
                </div>
          
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Resend</button>
                </div>
              </form>
            </Modal>
          </div>
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

 

    window.app
      .service('mpesa-pull-requests')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {            
            shortcode:d.shortcode,          
            "StartDate": d.StartDate,
            "EndDate": d.EndDate,
            "OffSetValue": d.OffSetValue,          
            "ResponseRefID": d.ResponseRefID,
            "ResponseCode": d.ResponseCode,
            "ResponseMessage": d.ResponseMessage,
            status: (
              <span
                className={`badge badge-${window.global_status[d.status]?.theme
                  }`}>
                {window.global_status[d.status]?.name}{' '}
              </span>
            ),                      
            "date_created":moment.utc(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
          
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };

  updateRequest = (event) => {
    event.preventDefault();
      let id = this.state.id;
      let data = {
        initiator_id: this.initiator_id,
        account_no: this.state.account_no,
        reference_id: this.state.reference_id
        
      };
      if (
        !window.confirm("Are you sure that you want to Resend this transaction?")
      )
        return false;

      // console.log(data)

      window.app
        .service('transaction-requests')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Transaction updated Successfully',
            buttons: true,
          });
         this.refreshTable('default');
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
    
  };
}

export default Customers;
