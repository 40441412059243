import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';

class MakerChecker extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav name='Maker Checker'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Filter
              filter={[
                {
                  label: 'Status',
                  name: 'service_response_status',
                  options: [
                    {
                      label: 'Pending',
                      value: null,
                      default: true,
                    },
                    {
                      label: 'Approved',
                      value: 200,
                    },
                    {
                      label: 'Rejected',
                      value: 400,
                    },
                  ],
                },
              ]}
              // dateRange={['startdate', 'enddate']}
              // dateRangeLabel='Date Range'
              onChange={(filter) => {
                let { query } = this.state;

                this.setState({ query: { ...query, ...filter } });
                setTimeout(() => {
                  this.fetchMakerChecker();
                }, 0);
              }}
            />

            <Table
              //   search={[ 'first_name', 'other_name']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchMakerChecker();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
            />
          </div>
        </div>

        {this.state.json && (
          <Modal
            visible={true}
            close={() => {
              this.setState({ json: null });
            }}
          >
            <h5 className='font-weight-bold'>Data Preview</h5>
            <hr />
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              src={this.state.json}
            />
          </Modal>
        )}
      </div>
    );
  }

  fetchMakerChecker = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

    window.app
      .service('service-transactions')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            admin: d.adminObj.name,

            description: d.description,
            // id: d.id,
            level: d.level,
            service_method: d.service_method,
            service_name: d.service_name,
            service_payload: d.service_payload && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() =>
                  this.setState({ json: JSON.parse(d.service_payload) })
                }
              >
                view
              </button>
            ),
            service_response_status: d.service_response_status,
            service_response_txt: d.service_response_txt && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() =>
                  this.setState({ json: JSON.parse(d.service_response_txt) })
                }
              >
                view
              </button>
            ),
            transaction_id: d.transaction_id,
            transaction_type: (
              <span className={`badge badge`}> {d.transaction_type} </span>
            ),
            tries: d.tries,

            action: !d.service_response_status ? (
              <div className='d-flex flex-row'>
                <Link
                  to={`/maker-checker/${d.id}/${d.service_response_status}`}
                  className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                >
                  Approve
                </Link>

                <Link
                  to={`/maker-checker/${d.id}/${d.service_response_status}`}
                  className='btn btn-sm btn-round btn-outline-danger px-3'
                >
                  Reject
                </Link>
              </div>
            ) : (
              <Link
                to={`/maker-checker/${d.id}/${d.service_response_status}`}
                className='btn btn-sm btn-round btn-outline-primary px-3'
              >
                view
              </Link>
            ),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default MakerChecker;
