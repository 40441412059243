import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'

import './globals';
import './assets/css/global.css';

// pages
import Login from './pages/login';
import Home from './pages/home';
import Welcome from './pages/welcome';

import Menu from './components/Menu';
import Alert from './components/alert';
import Admins from './pages/users/admins';
import Roles from './pages/admin/roles';
import IPAdress from './pages/ip_address';
import AdminCreate from './pages/admin/adminCreate';
import MakerChecker from './pages/admin/makerChecker';
import MakerCheckerLogs from './pages/admin/makerCheckerLogs';
import Dashboards from './pages/dashboards';
import portalLogs from './pages/portal/portalLogs';
import buyAirtime from './pages/airtime/airtimeCreate';
import Customers from './pages/users/customers';
import CustomersLogs from './pages/users/customersLogs';
import Dbcdr from './pages/messages/dbcdr';
import Dbqueue from './pages/messages/dbqueue';
import Smsblast from './pages/messages/smsblast';
import CommandTypes from './pages/messages/command_types';
import RequestsLog from './pages/requests/requestsLog';
import RequestsLogArchive from './pages/requests/requestsLogArchive';
import requestsLogFailed from './pages/requests/requestsLogFailed';
import requestsLogReversed from './pages/requests/requestsLogReversed';
import requestsLogQueue from './pages/requests/requestsLogQueue';
import telkomSuccessful from './pages/requests/telkomSuccessful';
import telkomPending from './pages/requests/telkomPending';
import customerDetails from './pages/users/customerDetails';
import Transactions from './pages/transactions/transactions';
import PullRequest from './pages/transactions/pullrequest';
import PullResult from './pages/transactions/pullresult';
import DepositsRecon from "./pages/transactions/depositsRecon";
import Api from './pages/transactions/api';
import Dealers from './pages/dealers/customers';
import dealerDetails from './pages/dealers/customerDetails';
import Resellers from './pages/reseller/customers';
import ServiceClient from './pages/reseller/serviceClients';
import Users from './pages/reseller/users';
import resellerDetails from './pages/reseller/customerDetails';
import CustomerCreate from './pages/reseller/customerCreate';
import ServiceClientCreate from './pages/reseller/ServiceClientCreate';
import UserClientCreate from './pages/reseller/userClientCreate';
import Agents from './pages/agents/customers';
import agentDetails from './pages/agents/customerDetails';
import { default as PaymentResults } from './pages/payment_requests/Results';
import { default as PaymentResultDetails } from './pages/payment_requests/ResultDetails';
import { default as MpesaSTKDetails } from './pages/mpesa/stk';
import { default as MpesaC2BResults } from './pages/mpesa/c2b_results';
import Withrawals from './pages/finance/withdrawals';
import Deposits from './pages/finance/deposits';
import Sales from './pages/finance/deposits';
import Access from './components/accessManager';
import AdminEdit from './pages/admin/adminEdit';
import { GitPullRequest } from 'react-feather';

// check token validity
let checkTokenValidity = () => {  
  if(Date.now()/1000 > localStorage.getItem("timeexp")){
    window.logout(true);
    setTimeout(() => {
      alert("Your Session has expired");
    }, 0);  
  }else {
    setTimeout(() => {
      //console.log("checking validity");
      if (localStorage.token) {
        checkTokenValidity();
      }
    }, 30000);
  }

};
window.global_state = [
  {
    theme: "danger",
    desc: "Inactive",
  },
  {
    theme: "success",
    desc: "Active"
  },
  {
    theme: "success",
    desc:""
  }
]
window.global_live = [
  {
    theme: "danger",
    desc: "Sandbox"
  
  },
  {    
    theme: "success",
    desc: "Production",
  },
 
]


window.global_months = [
  {
    name: "Jan",
  },
  {
    name: "Feb"
  },
  {
  name: "Mar"
  },
  {
    name: "Apr",
  },
  {
    name: "May"
  },
  {
  name: "Jun"
  },
  {
    name: "Aug",
  },
  {
    name: "Sep"
  },
  {
  name: "Oct"
  },
  {
    name: "Nov",
  },
  {
    name: "Dec"
  },
 
]
window.global_days = [
  {
    name: "Sun",
  },
  {
    name: "Mon",
  },
  {
    name: "Tue"
  },
  {
    name: "Wed",
  },
  {
    name: "Thur"
  },
  {
    name: "Fri",
  },
  {
    name: "Sat"
  },
]





class App extends Component {
  state = { blur: false, authenticated: false };
  render() {
    return (
      <>

        <Alert
          onRef={(ref) => {
            window.alert2 = ref;
          }}
          toggle={(blur) => {
            this.setState({ blur });
          }}></Alert>
        <div
          className={`page-blur pa
        ge ${this.state.blur && 'blur-alert'}`}>
          <Access permission='all'>
            <Route path='/login' exact component={Login}></Route>
          </Access>

          {this.state.authenticated && (
            <Portal hist={this.props.history}></Portal>
          )}
        </div>
        <div
          className={`preloader-cover ${this.state.loaded && 'hide-preloader'
            }`}>
          <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
            <div className='nest-parent'>
              <div id='nest1'></div>
            </div>
          </div>
        </div>
      </>
    );
  }


  componentDidMount() {
    window.app
      .reAuthenticate()
      .then((response) => {
        // console.log(response);
        this.fetchDefaults();
        this.fetchClients();

        let path = window.location.pathname;

        console.log('path before ', path);

        if (path.includes('login') || path.includes('get-started'))
          return false;
        console.log('path after ', path);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loaded: true, authenticated: false });
        let p = window.location.pathname;
        if (!['/password-change', '/password-forgot'].includes(p))
          this.props.history.push('/login');
      });
  }



  get = (model, callback, filter) => {
    window.app
      .service(model)
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          ...(filter ? filter : {}),
        },
      })
      .then((response) => {
        let data = [];
        response.data.map((d) => {
          data[d.id] = d;
        });
        callback(data);
        this.verifyFetch();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  getclients = (model, callback, filter) => {
    window.app
      .service(model)
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          ...(filter ? filter : {}),
        },
      })
      .then((response) => {
        let data = [];
        response.map((d) => {
          data[d.id] = d;
        });
        callback(data);
        this.verifyFetch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchDefaults = () => {
    this.get(
      `admin-group-roles`,
      (response) => {
        let roles = {};
        console.log('admin roles',response)

        response.map((d) => {
          if (!roles[d.admin_role.name]) roles[d.admin_role.name] = [];
          roles[d.admin_role.name].push(d.admin_group.id);
        });

        window.roles = roles;
      },
      {
        group_id: window.user && window.user.admin.group_id,
      }
    );

    this.get(`admin-groups`, (response) => {
      console.log(response);
      window.groups = response;
    });

    this.get(`command-types`, (response) => {
      console.log(response);
      window.command_types = response;
    });

    this.get(`status`, (response) => {
      window.global_status = response;
      // console.log(window.global_status)
      window.global_status[1].theme = 'success';
      window.global_status[2].theme = 'primary';
      window.global_status[3].theme = 'danger';
      window.global_status[4].theme = 'danger';
      window.global_status[5].theme = 'success';
      window.global_status[6].theme = 'success';
      window.global_status[7].theme = 'primary';
      // window.global_status[8].theme = 'primary';
    });

    this.get(`user-type`, (response) => {
      window.userTypes = response;
     
    });
    this.get(`service-clients`, (response) => {
    
      window.serviceClients = response;
     
    });
     this.get(`services`, (response) => {
      window.services = response;
     
     });
     this.get(`users`, (response) => {
      window.users = response;
     
     });

 
    this.verifyFetch();
  };
  fetchClients(){
    this.getclients(`clients`, (response) => {
      console.log('clients are',response)
      window.clients = response;
     
     });


 
    this.verifyFetch();

  }

  verifyFetch = () => {
    if (
      window.roles &&
      window.global_status &&
      window.groups &&
      window.userTypes
      // window.serviceClients &&
      // window.services && 
      // window.users &&
      // window.clients   

    ) {
      let loaded = false;
      if (localStorage.user) loaded = true;
      this.setState({ authenticated: true, loaded });
    }
  };
}

class Portal extends Component {
  state = { idleTimer: null };
  history = this.props.hist;
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 60}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <div id='wrapper' className='pt-5 mt-md-0 pt-md-0 mt-2'>
          <Menu></Menu>
          <div id='content-wrapper' className='d-flex flex-column bg-light'>
            <div className='d-flex flex-fill flex-column'>
              <Access permission='all'>
                <Route path='/' exact component={Welcome} />
              </Access>

              <Access permission='all'>
                <Route path='/home' exact component={Home} />
              </Access>


              <Access permission='ADMIN_READ'>
                <Route path='/admins' exact component={Admins} />
              </Access>
              <Access permission='ADMIN_CREATE'>
                <Route path='/admin-create' exact component={AdminCreate} />
              </Access>

              <Access permission='ADMIN_CREATE'>
                <Route path='/admins/edit/:id' exact component={AdminEdit} />
              </Access>

              <Access permission='ROLES_READ'>
                <Route path='/roles' exact component={Roles} />
              </Access>
              <Access permission='IP_ADDRESS_READ'>
                <Route path='/ip' exact component={IPAdress} />
              </Access>
              <Access permission='MAKER_CHECKER_READ'>
                <Route path='/maker-checker' exact component={MakerChecker} />
              </Access>

              <Access permission='all'>
                <Route path='/dashboards/:type' exact component={Dashboards} />
              </Access>
              <Access permission='MAKER_CHECKER_CONFIRM'>
                <Route
                  path='/maker-checker/:id/:status'
                  exact
                  component={MakerCheckerLogs}
                />
              </Access>
              <Access permission='PORTAL_LOGS'>
                <Route path='/portal-logs' exact component={portalLogs} />
              </Access>
              <Access permission='BUY_AIRTIME'>
                <Route path='/buy-airtime' exact component={buyAirtime} />
              </Access>

              <Access permission='CUSTOMER_READ'>
                <Route path='/customers' exact component={Customers} />
              </Access>
              <Access permission='CUSTOMER_LOGS'>
                <Route path='/customer-logs' exact component={CustomersLogs} />
              </Access>

              <Access permission='TRANSACTION_READ'>
                <Route path='/transactions' exact component={Transactions} />
              </Access>
              <Access permission='TRANSACTION_READ'>
                <Route path='/pull-requests' exact component={PullRequest} />
              </Access>
              <Access permission='TRANSACTION_READ'>
                <Route path='/pull-results' exact component={PullResult} />
              </Access>
              <Access permission='TRANSACTION_READ'>
                <Route path="/deposits-c2b" exact component={DepositsRecon} />
              </Access>

              <Access permission='SEARCH_READ'>
                <Route path='/api' exact component={Api} />
              </Access>
              <Access permission='MESSAGE_READ'>
                <Route path='/dbcdr' exact component={Dbcdr} />
              </Access>
              <Access permission='MESSAGE_READ'>
                <Route path='/dbqueue' exact component={Dbqueue} />
              </Access>
              <Access permission='MESSAGE_READ'>
                <Route path='/command-types' exact component={CommandTypes} />
              </Access>
              <Access permission='MESSAGE_READ'>
                <Route path='/smsblast' exact component={Smsblast} />
              </Access>
              <Access permission='REQUEST_READ'>
                <Route path='/request-log' exact component={RequestsLog} />
              </Access>
              <Access permission='REQUEST_READ'>
                <Route path='/request-log-archive' exact component={RequestsLogArchive} />
              </Access>
              <Access permission='REQUEST_READ'>
                <Route path='/request-log-failed' exact component={requestsLogFailed} />
              </Access>
              <Access permission='REQUEST_READ'>
                <Route path='/request-log-reversed' exact component={requestsLogReversed} />
              </Access>
              <Access permission='REQUEST_READ'>
                <Route path='/request-log-queue' exact component={requestsLogQueue} />
              </Access>
              <Access permission='WITHDRAWALS_READ'>
                <Route path='/withdrawals' exact component={Withrawals} />
              </Access>
              <Access permission='CUSTOMER_DETAILS_READ'>
                <Route path='/customers/:id' component={customerDetails} />
              </Access>

              <Access permission='DEALER_READ'>
                <Route path='/dealers' exact component={Dealers} />
              </Access>
              <Access permission='CUSTOMER_DETAILS_READ'>
                <Route path='/dealer/:id' component={dealerDetails} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/agents' exact component={Agents} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/agent/:id' component={agentDetails} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/reseller' exact component={Resellers} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/reseller/:client/:id/:units' component={resellerDetails} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/service_client/:id' component={ServiceClient} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/users/:id' component={Users} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/customer-create' exact component={CustomerCreate} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/serviceclient-create' exact component={ServiceClientCreate} />
              </Access>
              <Access permission='AGENT_READ'>
                <Route path='/userclient-create/:id' exact component={UserClientCreate} />
              </Access>
              <Access permission='PAYMENT_REQUEST_READ'>
                <Route path='/payment_requests' exact component={PaymentResults} />
              </Access>

              <Access permission='TELCOM_READ'>
                <Route path='/successful' exact component={telkomSuccessful} />
              </Access>
              <Access permission='TELCOM_READ'>
                <Route path='/pending' exact component={telkomPending} />
              </Access>


              <Access permission='PAYMENT_REQUEST_READ'>
                <Route path='/payment_requests/:id/:tab' component={PaymentResultDetails} />
              </Access>

              <Access permission='MPESA_STK_READ'>
                <Route path='/mpesa/stk' component={MpesaSTKDetails} />
              </Access>

              <Access permission='MPESA_STK_READ'>
                <Route path='/mpesa/c2b_results' component={MpesaC2BResults} />
              </Access>
              <Access permission='TRACK_SALES'>
                <Route path="/sales" exact component={Sales} />
              </Access>






              {/* <Access permission='TRANSACTIONS_READ'>
                <Route path='/transactions' exact component={Transactions} />
              </Access>

             

              

             


              <Access permission='SMS_READ'>
                <Route path='/sms' exact component={SMS} />
              </Access>

              <Access permission='SMS_READ'>
                <Route path='/sms-list' exact component={SMSList} />
              </Access>

              <Access permission='all'>
                <Route path='/sms-create' exact component={SMSCreate} />
              </Access>

             

              

              <Access permission='AUDIT_READ'>
                <Route path='/audit' exact component={AuditTrail} />
              </Access>

              <Access permission='AUDIT_READ'>
                <Route path='/audit-data' exact component={AuditData} />
              </Access>

              <Access permission='AUDIT_READ'>
                <Route path='/audit-entry' exact component={AuditEntry} />
              </Access>

              <Access permission='AUDIT_READ'>
                <Route path='/audit-error' exact component={AuditError} />
              </Access>
              <Access permission='AUDIT_READ'>
                <Route
                  path='/audit-javascript'
                  exact
                  component={AuditJavascript}
                />
              </Access>

              <Access permission='AUDIT_READ'>
                <Route path='/audit-mail' exact component={AuditMail} />
              </Access>

            

            

              // <Access permission='DEPOSITS_READ'>
              //   <Route path='/deposits' exact component={Deposits} />
              // </Access>
              <Access permission='STANDING_ORDERS_CREATE'>
                <Route
                  path='/order-create/:id/:msisdn'
                  exact
                  component={OrderCreate}
                />
              </Access> */}




            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    if (localStorage.token) {
      checkTokenValidity();
    }
    // if (!localStorage.user) 
    //   this.props.history.push('/login');  

  }
  handleOnAction = (event) => {
    // console.log('user did something', event)
  }

  handleOnActive = (event) => {
    // console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle = () => {
    window.logout(true);
    setTimeout(() => {
      alert("Your Session has expired");
    }, 0);
  }
}

export default withRouter(App);
