import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Customers Logs'
         ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
       

            <Table
              search={['cust_id', 'trx_id']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomersLogs();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}             
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomersLogs = () => {
    this.setState({ table_loading: true }); 
    let { query, filter } = this.state;
    let q = { ...query, ...filter };

    if (q.startdate) {
      q.date_created = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    window.app
      .service('customer-trx-log')
      .find({
        query: {
          ...q,
          agent_id:this.props.user,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
           
            "cust_id": d.cust_id,
            "narration": d.narration,          
            "dr": "KES "+parseFloat(d.dr).toLocaleString(),
            "cr": "KES "+parseFloat(d.cr).toLocaleString(),
            "prev_bal": "KES "+parseFloat(d.prev_bal).toLocaleString(),
            "new_bal": "KES "+parseFloat(d.new_bal).toLocaleString(),                 
            "date_created":moment.utc(d.date_created).format("DD MMMM, YYYY.  HH:mm:ss"),
            
            
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Customers;
