import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';
import Modal from "../../components/modal";
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw
} from "react-feather";
import ReactJson from 'react-json-view';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    updateModal: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Customers'
          buttons={[
            {
              text: 'Add a Customer',
              link: '/customer/create',
              permission: 'CUSTOMER_CREATE',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            {/* <Filter
              filter={[
                {
                  label: 'Status',
                  name: 'status_id',
                  options: [
                    {
                      label: 'All',
                      value: 0,
                      default: true,
                    },

                    ...window.global_status
                      .filter((d) => d.type.includes('cust'))
                      .map((d) => {
                        return {
                          label: d.status_description,
                          value: d.id,
                        };
                      }),
                  ],
                },
              ]}
              // dateRange={['startdate', 'enddate']}
              // dateRangeLabel='Date Range'
              onChange={(filter) => {
                let { query } = this.state;

                this.setState({ query: { ...query, ...filter } });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
            /> */}

            <Table
              search={['msisdn', 'cust_name']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
               {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json.addOns}
              />
            </Modal>
          )}

               <Modal
              visible={this.state.updateModal}
              close={() => this.setState({ updateModal: false, errors: {} })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.updateCustomer}>               
                  <div className="mx-3 d-inline-block mb-3">
                    <span className="ml-1 mb-2 d-block text-capitalize">Access Levels:</span>
                    <select className="form-control bg-light text-input" name="addOns" id="addOns" type="text" value={this.state.addOns} onChange={this.handleChange} style={{ width: '400px' }}>
                      <option value="1">Level 1</option>
                      <option value="2">Level 2</option>
                      <option value="3">Level 3</option>
                      <option value="4">Level 4</option>
                      <option value="5">Level 5</option>
                    </select>
                  </div>
                 {/* } */}
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
                </div>
              </form>
            </Modal>





          </div>
        </div>
      </div>
    );
  }




  updateCustomer = (event) => {
    event.preventDefault();    
     
      let id = this.state.id;
      let num = parseInt(this.state.addOns);

   
      let data = {
       
        addOns: { "accessLevel": num }
        
      };

  

      console.log(data)
      if (
        !window.confirm("Are you sure that you want to Update Customer?")
      )
        return false;

    

      window.app
        .service('customer')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Customer updated Successfully',
            buttons: true,
          });
          this.refreshTable('default');
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
    
  };
   refreshTable(table_id) {
    document.getElementById(`${table_id}_refresh_table`).click()
  }

   handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }











  fetchCustomers = () => {
    console.log(window.userTypes);
    let { query } = this.state;

    this.setState({ table_loading: true });

 

    window.app
      .service('customer')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {

        console.log('response is',response)
        response.data = response.data.map((d) => {
       
        let addon;
        d.addOns &&(Object.keys(d.addOns).forEach((key) => {
          addon = d.addOns[key];
        }))
        console.log('addon is',addon)

        //console.log(d.addOns &&(Object.values(d.addOns)));
       

          return { 

            addOns: d.addOns && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() => 
                this.setState({ json: d })
              }>
                view
              </button>
            ),
           name: d.cust_name,
            phone: d.msisdn,           
            commission: "KES "+parseFloat(d.commission).toLocaleString(), 
            net_value: "KES "+parseFloat(d.net_value).toLocaleString(),
            unit_consumed: "KES "+parseFloat(d.unit_consumed).toLocaleString(),       
            points: d.points,
            user_type: (
              <span
                className={`badge badge-${
                  window.userTypes[d.user_type]
                }`}>
                {window.userTypes[d.user_type]?.user_type}{' '}
              </span>
            ),
          //   status: (<span
          //     className={`badge badge-${window.global_status[d.status]?.theme
          //         }`}>
          //     {window.global_status[d.status]?.desc}{}
          // </span>),
            status: (
              <span
                className={`badge badge-${
                  window.global_status[d.status].theme
                }`}>
                {window.global_status[d.status]?.name}{' '}
              </span>
            ),
            // user_type: d.user_type,
                       
            last_activity_date: moment(d.last_activity_date).format('YYYY-MM-DD hh:mm:ss'),        
            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),

            //   action: (
            //   <div className="d-flex flex-row">
             
                  
            //         <Access permission='CUSTOMER_DETAILS_READ'>
            //    <Link
            //     className='btn btn-sm btn-round btn-outline-primary px-3'
            //      to={`/customers/${d.id}/details`}>
            //       View
            //     </Link>
            //    </Access>
            //       {window.user.admin['group']['name'] != 'CCR' &&
            //         (<button
            //           onClick={() => {
            //             this.setState({ reverseModal: true });

            //           }}
            //           className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
            //         >
            //           Update
            //         </button>)}
               
            //   </div>
            // )
            
            
            action: (
            <div>
              <Access permission='CUSTOMER_DETAILS_READ'>
                <Link
                  className='btn btn-sm btn-round btn-outline-primary px-3'
                  to={`/customers/${d.id}/details`}>
                  View
                </Link>
              </Access>

              {window.user.admin['group']['name'] != 'CCR' &&
              (<button
                onClick={() => {
                  this.setState({ updateModal: true,activeDetails: d, addOns: addon, id: d.id });

                }}
                className="btn btn-sm btn-round btn-outline-primary px-3"
              >
                Update
              </button>)}
              </div>

           
            ),
            




          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Customers;
