import React, { Component } from 'react';
import moment from 'moment';
import { DollarSign, User, LogOut } from "react-feather";

class UserSummarry extends Component {
  state = { data: {},account:{} };
  render() {
    return (
      <div>
        <div className='row p-3'>
        <div className="col-md-6">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <User size={18}></User> Client Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <DollarSign size={18}></DollarSign> Account Details
              </div>
              {Object.keys(this.state.account).map(d => {
                if (d === "break" || d === "brea")
                  return <div className="p-1 bg-light"></div>;

                return (
                  <li className="list-group-item d-flex flex-row text-dark">
                    <div className="user-detail-title font-weight-bold text-capitalize">
                      {d.replace(/_/g, " ")}
                    </div>
                    <div>{this.state.account[d]}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    console.log(this.props.data);
    let d = this.props.data;
    let sum = [];

    
    let data = {    
      ClientId: d.ClientId, 
      ApiKey: d.ApiKey,      
      is_active: d.is_active,     
      created_at: moment(d.created_at).format('Do MMMM YYYY hh:mm'),     
      updated_at: d.updated_at,     
     
    };
    let account = {
      Balance: "KES " +
      parseFloat(
        parseFloat(d.units)
      ).toLocaleString(),
      minimum_units: "KES " +
      parseFloat(
        parseFloat(d.minimum_units)
      ).toLocaleString(),
      percentage_commission: "KES " +
      parseFloat(
        parseFloat(d.percentage_commission)
      ).toLocaleString(),
     
         
     
    };

    this.setState({ data,account });
  }
}

export default UserSummarry;
