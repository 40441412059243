import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import {
    Plus,
    Target,
    Circle,
    Smartphone,
    Edit2,
    PlusSquare
} from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import Access from "../../components/accessManager";

class CommandTypes extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false
    };
    timeout = null;
    render() {
        return (
            <div className="">
                <Nav
                    name="CommandTypes"
                    buttons={[
                        {
                            text: "Add Command",
                            onClick: () => {
                                this.setState({ addModal: true });
                            },
                            permission: "COMMAND_TYPE_CREATE"
                        }
                    ]}
                ></Nav>

                <div className="mt-3 table-card p-4 border-0 card shado mx-3 shadow">
                    <Filter
                        filter={[

                        ]}
                        dateRange={['startdate', 'enddate']}
                        dateRangeLabel='Date Range'
                        onChange={(filter) => {
                            let { query } = this.state;

                            this.setState({ query: { ...query, ...filter } });
                            setTimeout(() => {
                                this.fetchCustomers();
                            }, 0);
                        }}
                    />
                    <Table
                        search={["id","name"]}
                        // sort="created_at"
                        // sortDirection={-1}
                        refreshAllowed={true}
                        data={this.state.tableData}
                        fetch={params => {
                            this.setState({ query: params });
                        }}
                        loading={this.state.table_loading}
                        fetchError={this.state.tableError}
                    />
                </div>

                <Modal
                    visible={this.state.editModal}
                    close={() => this.setState({ editModal: false })}
                >
                    <div className="d-flex flex-row align-items-center">
                        <Edit2 className="mr-3"></Edit2>
                        <h5 className="m-0 mt-1">Let ReBlast Off!</h5>
                    </div>
                    <div className="mt-3">
                        {typeof this.state.active !== "undefined" && (
                            <Form
                                inputs={[
                                    {
                                        label: "Command name",
                                        name: "name",
                                        type: "text",
                                        value: this.state.tableData.data[this.state.active].name
                                    },
                                    {
                                        label: "Command Comma Separate Scopes",
                                        name: "scope",
                                        type: "text",
                                        value: this.state.tableData.data[this.state.active].scope
                                    },
                                    {
                                        label: "Additional Data(Most Preferred, JSON)",
                                        name: "data",
                                        type: "text",
                                        value: this.state.tableData.data[this.state.active].data
                                    }


                                ]}
                                submit={data => {
                                    console.log(data);
                                    this.setState({ details: data });
                                    setTimeout(() => {
                                        this.update(data);
                                    }, 0);
                                }}
                            />
                        )}
                    </div>
                </Modal>

                <Modal
                    visible={this.state.addModal}
                    close={() => this.setState({ addModal: false })}
                >
                    <div className="d-flex flex-row align-items-center">
                        <PlusSquare className="mr-3"></PlusSquare>
                        <h5 className="m-0 mt-1">Let Blast Off!</h5>
                    </div>
                    <div className="mt-3">
                        <Form
                            inputs={[
                                {
                                    label: "Command name",
                                    name: "name",
                                    type: "text",
                                    value: ""
                                },
                                {
                                    label: "Command Comma Separate Scopes",
                                    name: "scope",
                                    type: "text",
                                    value: ""
                                },
                                {
                                    label: "Additional Data(Most Preferred, JSON)",
                                    name: "data",
                                    type: "text",
                                    value: ""
                                }
                            ]}
                            submit={data => {
                                console.log(data);
                                this.setState({ details: data });
                                setTimeout(() => {
                                    this.add(data);
                                }, 0);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }


    fetchCommandTypes = () => {
        this.setState({ table_loading: true });

        window.app
            .service('command-types')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                this.setState({response:response.data});

                response.data = response.data.map((d,i) => {
                    return {
                        id: d.id,
                        name: d.name,
                        scope:(<code>{d.scope}</code>),
                        data:d.data,
                        date_created:d.dateCreated && moment(d.dateCreated).format('YYYY-MM-DD hh:mm:ss'),
                        action: (
                            <div className="d-flex flex-row">
                                <Access permission="COMMAND_TYPE_EDIT">
                                    <button
                                        className="btn btn-round px-3 font-weight-bold btn-primary  mr-3"
                                        onClick={() => {
                                            this.setState({
                                                editModal: true,
                                                active: i,
                                                activeID: d.id
                                            });
                                            // console.log(this.state.response.data);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </Access>
                                <Access permission="COMMAND_TYPE_DELETE">
                                    <button
                                        className="btn btn-round px-3 font-weight-bold btn-danger mr-3"
                                        onClick={() => this.delete(d.id)}
                                    >
                                        Delete
                                    </button>
                                </Access>
                            </div>
                        )
                    };
                });

                this.setState({ tableData: response, table_loading: false });
            })
            .catch((err) => {
                this.setState({ table_loading: false });
                console.error(err);
            });
    };



    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function() {
                $t.fetchCommandTypes();
            }, 100);
        }
    }

    add = data => {
        if (!window.confirm("Are you sure that you want add this Entry?"))
            return false;

        window.app
            .service('command-types')
            .create(data)
            .then(response => {
                console.log(response);
                if (response.cod) {
                    alert(
                        response.message +
                        " \n " +
                        (response.errors[0] ? response.errors[0].message : "")
                    );
                    this.setState({ addModal: false });
                } else {
                    alert("added Successfully");
                    window.location.reload();

                    this.setState({ addModal: false });
                }
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };

    update = data => {
        if (!window.confirm("Are you sure that you want update this Entry?"))
            return false;

        window.app
            .service('command-types')
            .patch(this.state.activeID,data)
            .then(response => {
                console.log(response);
                if (response.cod) {
                    alert(
                        response.message +
                        " \n " +
                        (response.errors[0] ? response.errors[0].message : "")
                    );
                    this.setState({ addModal: false });
                } else {
                    console.log(response);
                    window.location.reload();
                    alert("Updated successfully");
                    this.setState({ editModal: false });
                }
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };

    delete = id => {
        if (!window.confirm("Are you sure that you want to delete this Entry?"))
            return false;

        window.app
            .service('command-types')
            .delete(id)
            .then(response => {
                console.log(response);
                if (response.cod) {
                    alert(
                        response.message +
                        " \n " +
                        (response.errors[0] ? response.errors[0].message : "")
                    );
                } else {
                    alert("Deleted successfully");
                    window.location.reload();
                }
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };
}

export default CommandTypes;
