import React, { Component } from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import TextareaCounter from 'react-textarea-counter';





class AdminCreate extends Component {
  state = {};
  render() {
  
    return (
      
      <div className=''>
        <Nav name='Create Client User'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
          <div className='order-form'>
          <form onSubmit={this.createClient}>
              <div className="row">
                  <div className="col-lg-5">            
                
               
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Name:</span>
                  <input className="form-control bg-light text-input" name="name" id="name" type="text" initialValue={this.state.name} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div> 

                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Email:</span>
                  <input className="form-control bg-light text-input" name="email" id="email" type="text" initialValue={this.state.email} onChange={this.handleChange} style={{ width: '400px' }} />               
                  </div>
                 </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-5">           
                 
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Password:</span>
                  <input className="form-control bg-light text-input" name="password" id="password" type="password" initialValue={this.state.password} onChange={this.handleChange} style={{ width: '400px' }} />               
                  </div>
                  </div>
               
                   <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Create</button>
                </div>

                
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  createClient = (event) => {
    event.preventDefault();
   
    let data = {
      client_id: this.props.match.params.id,
      name:this.state.name,
      email: this.state.email,
      password:this.state.password,
      roles: "*",
    
    };
    if (
        !window.confirm("Are you sure that you want to create Client User?")
    )
        return false;

      console.log(data)

    window.app
        .service('users')
        .create(data)
        .then((response) => {
            this.setState(response);

            window.alert2.show({
                loader: false,
                icon: <CheckCircle size={60}></CheckCircle>,
                title: 'Client User Added Successfully',
                buttons: true,
            });
            window.location = '/reseller';
        })
        .catch((err) => {
            window.alert2.show({
                loader: false,
                icon: <XCircle size={60} className='text-danger'></XCircle>,
                title: 'Error',
                message: err.message,
                buttons: true,
            });
            console.error(err);
        });

};
handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
        ...prevState,
        [id]: value
    }))
}
}

export default AdminCreate;
