import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import {
    Plus,
    Target,
    Circle,
    Smartphone,
    Users,
    CheckCircle,
    UserPlus,
    UserCheck
} from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";

class Roles extends Component {
    state = {
        groups: [],
        roles: [],
        groupRoles: [],
        createdRoles: [],
        deletedRoles: [],
        addModal: false,
        groupModal: false
    };
    timeout = null;
    render() {
        return (
            <div className="">
                <Nav
                    name="Groups"
                    buttons={[
                        {
                            text: "Add a group",
                            onClick: () => {
                                this.setState({ groupModal: true });
                            },
                            access: "GROUP_ADD"
                        },
                        {
                            text: "Add a role",
                            onClick: () => {
                                this.setState({ addModal: true });
                            },
                            access: "all"
                        }
                    ]}
                ></Nav>

                <div className="mt-3 mb-5 table-card p-2 border-0 card shado mx-3 shadow">
                    {this.state.groups.length > 0 &&
                    this.state.roles.length > 0 &&
                    this.state.groupRoles.length > -1 &&
                    this.state.groups.map((d, i) => (
                        <div className="card role-card m-3" key={i}>
                            <div className="card-header text-dark d-flex flex-row align-items-center">
                                <Users size={20} className="mr-2"></Users>{" "}
                                <span className="font-weight-bold group-name">{d.name}</span>
                            </div>
                            <div className="card-content p-3">
                                <div className="row">
                                    {this.state.roles.map((d1, i1) => {
                                        return (
                                            <div className="col-md-3" key={i1}>
                                                <label>
                                                    <input
                                                        class="styled-checkbox"
                                                        id={d1.name + i}
                                                        type="checkbox"
                                                        value="value1"
                                                        onChange={() => {
                                                            if (this.state.groupRoles[d.id]) {
                                                                let {
                                                                    groupRoles,
                                                                    createdRoles,
                                                                    deletedRoles
                                                                } = this.state;

                                                                if (groupRoles[d.id].includes(d1.id)) {
                                                                    groupRoles[d.id].splice(
                                                                        groupRoles[d.id].indexOf(d1.id),
                                                                        1
                                                                    );

                                                                    if (!deletedRoles[d.id])
                                                                        deletedRoles[d.id] = [];

                                                                    deletedRoles[d.id].push(d1.id);
                                                                } else {
                                                                    groupRoles[d.id].push(d1.id);

                                                                    if (!createdRoles[d.id])
                                                                        createdRoles[d.id] = [];

                                                                    createdRoles[d.id].push(d1.id);
                                                                }

                                                                this.setState({ groupRoles });
                                                            }
                                                        }}
                                                        checked={
                                                            this.state.groupRoles[d.id]
                                                                ? this.state.groupRoles[d.id].includes(d1.id)
                                                                : false
                                                        }
                                                    />
                                                    <label for={d1.name + i}>
                                                        {d1.name.replace(/_/g, " ")}
                                                    </label>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {(this.state.deletedRoles.length > 0 ||
                    this.state.createdRoles.length > 0) && (
                    <button
                        className="btn btn-primary btn-round pl-3 pr-5 btn-lg font-weight-bold role-update-button"
                        onClick={this.update}
                    >
                        <CheckCircle className="mr-3"></CheckCircle> Update Changes
                    </button>
                )}

                <Modal
                    visible={this.state.addModal}
                    close={() => this.setState({ addModal: false })}
                >
                    <div className="d-flex flex-row align-items-center">
                        <UserCheck className="mr-3"></UserCheck>
                        <h5 className="m-0 mt-1">Create a Role</h5>
                    </div>
                    <div className="mt-3">
                        <Form
                            inputs={[
                                {
                                    label: "Role Name",
                                    name: "name",
                                    type: "text",
                                    value: ""
                                },
                                {
                                    label: "Role Code",
                                    name: "code",
                                    type: "text",
                                    value: ""
                                }
                            ]}
                            submit={data => {
                                this.createTimRole(data);
                            }}
                        />
                    </div>
                </Modal>

                <Modal
                    visible={this.state.groupModal}
                    close={() => this.setState({ groupModal: false })}
                >
                    <div className="d-flex flex-row align-items-center">
                        <Users className="mr-3"></Users>
                        <h5 className="m-0 mt-1">Create a Group</h5>
                    </div>
                    <div className="mt-3">
                        <Form
                            inputs={[
                                {
                                    label: "Group Name",
                                    name: "name",
                                    type: "text",
                                    value: ""
                                }
                            ]}
                            submit={data => {
                                this.createGroup(data);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }

    get = (url, callback) => {
        window.app
            .service(url)
            .find({query:{$limit:200}})
            .then(response => {
                // console.log(response);
                callback(response.data);
            })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    fetchRoles = () => {
        this.get("/admin-groups", groups => {
            this.setState({ groups });
        });

        this.get("/admin-roles", roles => {
            this.setState({ roles });
        });

        this.get("/admin-group-roles", roles => {
            let groupRoles = [];
            roles.map(d => {
                if (!groupRoles[d.group_id]) groupRoles[d.group_id] = [];
                groupRoles[d.group_id].push(d.role_id);
            });

            this.setState({ groupRoles, initialRoles: roles });
        });
    };

    componentDidMount() {
        this.fetchRoles();
    }

    update = () => {
        let { initialRoles, deletedRoles, createdRoles } = this.state;

        // delete group roles

        let rawroles = initialRoles.filter(d1 => {
            return (
                deletedRoles[d1.group_id] &&
                deletedRoles[d1.group_id].includes(d1.role_id)
            );
        });
        let roles = rawroles.map(d => {
            this.deleteRole(d.id);
            return d.id;
        });

        // console.log(roles);

        createdRoles.map((d, i) => {
            d.map(d1 => {
                this.createRole(i, d1);
            });
        });

        // create group roles
    };

    createRole = (group_id, role_id) => {
        console.log("creating role ...");

        window.app
            .service('admin-group-roles')
            .create({ group_id, role_id })
            .then(response => {
                console.log(response);
                this.verifyComplete();
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };

    deleteRole = id => {
        console.log("deleting role ...");
        window.app
            .service('admin-group-roles')
            .delete(id)
            .then(response => {
                console.log(response);
                this.verifyComplete();
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };

    updateCount = 1;

    verifyComplete = () => {
        // this.fetchRoles();
        this.setState({ createdRoles: [], deletedRoles: [] });

        let total = 0;
        this.state.createdRoles.map(d => {
            total += d.length;
        });
        this.state.deletedRoles.map(d => {
            total += d.length;
        });

        // if (this.updateCount === total) {
        //   this.fetchRoles();
        //   this.setState({ createdRoles: [], deletedRoles: [] });
        // }
    };

    createTimRole = data => {
        console.log("creating role ...");

        if (!window.confirm("Are you sure that you want to add this role?")) {
            return;
        }

        window.app
            .service('admin-roles')
            .create({
                name: data.name.toUpperCase(),
                code: data.code.toUpperCase()
            })
            .then(response => {
                console.log(response);
                alert("added");
                this.setState({ addModal: false });
                this.fetchRoles();
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };

    createGroup = data => {
        console.log("creating group ...");

        if (!window.confirm("Are you sure that you want to create this group?")) {
            return;
        }

        window.app
            .service('admin-groups')
            .create(data)
            .then(response => {
                console.log(response);
                alert("added");
                this.setState({ groupModal: false });
                this.fetchRoles();
            })
            .catch(d => {
                console.log("Error saving the data");
                console.log(d);
                this.setState({ modalVisible: false });
            });
    };
}

export default Roles;
