import React, { Component } from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import TextareaCounter from 'react-textarea-counter';




class AdminCreate extends Component {
  state = {};
  render() {
    return (
      <div className=''>
        <Nav name='Create Client'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
          <div className='order-form'>
          <form onSubmit={this.createClient}>
              <div className="row">
                  <div className="col-lg-5">
                  <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">RoleId:</span>                  
                  <select className="form-control bg-light text-input" name="RoleId" id="RoleId" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>            
                  <option value=''>Select Option</option>
                    <option value='2'>Reseller</option>
                    <option value='3'>Corporate(Send Api Credentials)</option>
                   </select>
                  </div>
                  
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">CompanyName:</span>
                  <input className="form-control bg-light text-input" name="CompanyName" id="CompanyName" type="text" initialValue={this.state.CompanyName} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">UserName:</span>
                  <input className="form-control bg-light text-input" name="UserName" id="UserName" type="text" initialValue={this.state.UserName} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">FirstName:</span>
                  <input className="form-control bg-light text-input" name="FirstName" id="FirstName" type="text" initialValue={this.state.FirstName} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">LastName:</span>
                  <input className="form-control bg-light text-input" name="LastName" id="LastName" type="text" initialValue={this.state.LastName} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>

                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-5">
                  
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">EmailAddress:</span>
                  <input className="form-control bg-light text-input" name="EmailAddress" id="EmailAddress" type="text" initialValue={this.state.EmailAddress} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Password:</span>
                  <input className="form-control bg-light text-input" name="Password" id="Password" type="text" initialValue={this.state.Password} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">PhoneNumber:</span>
                  <input className="form-control bg-light text-input" name="PhoneNumber" id="PhoneNumber" type="text" initialValue={this.state.PhoneNumber} onChange={this.handleChange} style={{ width: '400px' }} />               
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">is_active:</span>                  
                  <select className="form-control bg-light text-input" name="is_active" id="is_active" initialValue={this.state.value} onChange={this.handleChange} style={{ width: '400px' }}>            
                  <option value=''>Select Option</option>
                    <option value='1'>True</option>
                    <option value='0'>False</option>
                   </select>
                  </div>
          
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Create</button>
                </div>

                  </div>
              </div>
                          
             



               
                            

                          
 

                        </form>
          </div>
        </div>
      </div>
    );
  }

  createClient = (event) => {
    event.preventDefault();
    console.log('here',this.state.PhoneNumber);
 
    let data = {
        user_ref_id:window.users.id,
        RoleId: this.state.RoleId,
        CompanyName:this.state.CompanyName,
        UserName: this.state.UserName,
        FirstName:this.state.FirstName,
        LastName: this.state.LastName,
        EmailAddress:this.state.EmailAddress,
        Password: this.state.Password,
        PhoneNumber:this.state.PhoneNumber,
        is_active:this.state.is_active,
       
    };
    if (
        !window.confirm("Are you sure that you want to create the Client?")
    )
        return false;

     console.log(data)

    window.app
        .service('clients')
        .create(data)
        .then((response) => {
            this.setState(response);

            window.alert2.show({
                loader: false,
                icon: <CheckCircle size={60}></CheckCircle>,
                title: 'Client Added Successfully',
                buttons: true,
            });
            window.location = '/reseller';
        })
        .catch((err) => {
            window.alert2.show({
                loader: false,
                icon: <XCircle size={60} className='text-danger'></XCircle>,
                title: 'Error',
                message: err.message,
                buttons: true,
            });
            console.error(err);
        });

};
handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
        ...prevState,
        [id]: value
    }))
}
}

export default AdminCreate;
